import {
    Box,
    Button,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import axiosClient from "../../axios";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

const NewInsurer = ({ setIsNewInsurer }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(true);
    const [isError, setIsError] = useState(false);

    const closeSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarOpen(false);
    };

    const handleFormSubmit = (values) => {
        console.log(values);
        async function createInsurer() {
            const response = await axiosClient
                .post("/insurer", {
                    insurer_name: values.insurer_name,
                })
                .then((response) => {
                    if (response.status === 201) {
                        // Open SnackBar
                        setSnackBarOpen(true);
                        // Close create new template form
                        setIsNewInsurer(false);
                        console.log("Insurer created successfully!");
                    } else {
                        console.log("Error while creating an insurer!");
                    }
                })
                .catch((error) => {
                    setIsError(true);
                    console.log("ERROR: ", error.response.data.message);
                });
        }
        createInsurer();
    };

    const cancelCreateInsurer = () => {
        // Close create new template form
        setIsNewInsurer(false);
    };

    return (
        <Box m="20px">
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": {
                                    gridColumn: isNonMobile
                                        ? undefined
                                        : "span 4",
                                },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Insurer Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.insurer_name}
                                name="insurer_name"
                                error={
                                    !!touched.insurer_name &&
                                    !!errors.insurer_name
                                }
                                helperText={
                                    touched.insurer_name &&
                                    errors.insurer_name
                                }
                                sx={{ gridColumn: "span 4" }}
                            />
                        </Box>

                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button
                                sx={{
                                    marginRight: "10px",
                                    bgcolor: colors.redAccent[400],
                                }}
                                variant="contained"
                                onClick={cancelCreateInsurer}
                                startIcon={<CancelIcon />}
                            >
                                Cancel
                            </Button>

                            <Button
                                type="submit"
                                color="secondary"
                                variant="contained"
                                startIcon={<AddIcon />}
                            >
                                Create Insurer
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>

            <Snackbar
                open={snackBarOpen}
                autoHideDuration={6000}
                onClose={closeSnackbar}
            >
                <Alert
                    onClose={closeSnackbar}
                    severity="success"
                    color="success"
                    sx={{ width: "100%" }}
                >
                    Insurer created successfully!
                </Alert>
            </Snackbar>
        </Box>
    );
};

const checkoutSchema = yup.object().shape({
    insurer_name: yup.string().required("required"),
});
const initialValues = {
    insurer_name: "",
};

export default NewInsurer;
