import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const UserContext = createContext({
    token: null,
    user: null,
});

export const UserContextProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem("TOKEN") || "");
    const [user, setUser] = useState(localStorage.getItem("USER") || {});
    const location = useLocation();

    const saveToken = (token, user) => {
        localStorage.setItem("TOKEN", token);
        setToken(token);
        localStorage.setItem("USER", JSON.stringify(user));
        setUser(user);
    };

    const deleteToken = () => {
        localStorage.removeItem("TOKEN");
        setToken(null);
        localStorage.removeItem("USER");
        setUser(null);
    };


    useEffect(() => {
        if (localStorage.getItem("TOKEN")) {
            setToken(localStorage.getItem("TOKEN"));
        }

        if (localStorage.getItem("USER")) {
            setUser(JSON.parse(localStorage.getItem("USER")));
        }

    }, []);

    const value = {
        token,
        setToken,
        user,
        setUser,
        saveToken,
        deleteToken,
    };

    return (
        <UserContext.Provider value={value}> {children} </UserContext.Provider>
    );
};

export default UserContext;
