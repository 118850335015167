import * as React from "react";
import {
    Box,
    Grid,
    Typography,
    useTheme,
    Divider,
    Autocomplete,
    TextField,
    Button,
    Tab,
    Tabs,
} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbar,
} from "@mui/x-data-grid";
import { useState, useEffect, useContext } from "react";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axiosClient from "../../axios";
import { set } from "lodash";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import prettyDate from "../../formatdate";
import { compareAsc, format } from "date-fns";
import DownloadIcon from "@mui/icons-material/Download";
import DownloadPDF from "./DownloadPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import UserContext from "../../contexts/UserContext";
import CustomReports from "./CustomReports";

export default function ArchivedSurveys() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [username, setUsername] = useState("system");
    const [userLog, setUserLog] = useState([]);
    const { user } = useContext(UserContext);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    function CustomToolbar() {
        return (
            <PDFDownloadLink
                document={
                    <DownloadPDF
                        titleItem={selectedUser}
                        userLog={userLog}
                        byUser={true}
                    />
                }
                fileName={username + "_log.pdf"}
            >
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<DownloadIcon />}
                >
                    PDF
                </Button>
            </PDFDownloadLink>
        );
    }

    useEffect(() => {
        // If user if not defined, return
        if (!user) return;

        async function getUsers() {
            const response = await axiosClient.get("/user/users");
            if (response.data) {
                // console.log("All usersOrig: ", response.data);
                let all_users = response.data;
                // If Logged user.role == "Executive", get all users of their insurer
                // console.log("User.role: ", user?.role);
                if (user?.role == "Executive") {
                    // console.log("Entered Executive");
                    all_users = all_users.filter(
                        (item) => item.insurer_id === user.insurer_id
                    );
                }
                // console.log("All users: ", all_users);
                // Loop through object of response.data and create a new array of users
                const loaded_users = [];
                for (const key in all_users) {
                    let user = all_users[key];
                    loaded_users.push({
                        id: user.id,
                        insurer_id: user.insurer_id,
                        selectItem:
                            user.name +
                            "(" +
                            user.email +
                            ") ",
                    });
                }

                // Get Broker user emails to append to list of loaded users
                const broker_users = [];
                const broker_response = await axiosClient.get(
                    "/audit_trails/broker_users"
                );
                if (broker_response.data) {
                    // console.log("Broker users: ", response.data);
                    for (const key in broker_response.data) {
                        broker_users.push({
                            id: broker_response.data[key].user_id,
                            insurer_id: broker_response.data[key].insurer_id,
                            selectItem:
                                broker_response.data[key].broker_name +
                                " (" +
                                broker_response.data[key].user_id +
                                ") ",
                        });
                    }
                    // Concatenate audit sent by the system for this user's insurer
                    broker_users.push({
                        id: "System",
                        insurer_id: user.insurer_id,
                        selectItem: "System Generated",
                    });
                }

                // If logged in user is Underwriter, show only their activity log
                if (user.role == "Underwriter") {
                    const current_user = loaded_users.filter(
                        (item) => item.id === user.id
                    );
                    setUsers(
                        current_user.concat(
                            broker_users.filter(
                                (item) => item.insurer_id === user.insurer_id
                            )
                        )
                    );
                } else if (user.role == "Executive") { 
                    // If logged in user is Executive, show all activities of their insurer
                    setUsers(
                        loaded_users.concat(
                            broker_users.filter(
                                (item) => item.insurer_id === user.insurer_id
                            )
                        )
                    );
                }else { // If logged in user is Admin, show all users
                    setUsers(loaded_users.concat(broker_users));
                }
            }
        }
        getUsers();
    }, [user]);
    // console.log("LoadedUsers: ", users);

    useEffect(() => {
        async function getUserLog() {
            const response = await axiosClient.get(
                `/audit_trails/get_user_audits/${selectedUser?.id}`
            );
            if (response.data) {
                // console.log("User log: ", response.data);
                setUserLog(response.data);
            }
        }
        getUserLog();
    }, [selectedUser]);
    // console.log("User log: ", userLog);

    const handleChange = (event, user) => {
        if (user !== undefined) {
            setSelectedUser(user);
            // console.log("Selected user: ", user);
            setUsername(
                user.selectItem.substring(
                    user.selectItem.indexOf("(") + 1,
                    user.selectItem.indexOf("@")
                )
            );
            // console.log("Username: ", username);
        }
    };

    const columns = [
        {
            field: "created_at",
            headerName: "Date",
            flex: 1,
            renderCell: ({ row: { created_at } }) => {
                const formattedDate = format(
                    new Date(created_at),
                    "yyyy-MM-dd HH:mm"
                );

                return <Typography>{formattedDate}</Typography>;
            },
        },
        {
            field: "title",
            headerName: "Title",
            flex: 1,
        },
        {
            field: "description",
            headerName: "Activity Description",
            flex: 3,
            whiteSpace: "normal",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        // {
        //     field: "ip_address",
        //     headerName: "IP Address",
        //     flex: 1,
        // },
    ];
    const handleTabChange = (e, tabIndex) => {
        setCurrentTabIndex(tabIndex);
    };

    return (
        <Box m="20px">
            <Header title="AUDIT TRAIL" subtitle="Activity log of all users" />
            <Card
                variant="outlined"
                sx={{
                    marginTop: "40px",
                    borderTop: 3,
                    borderTopColor: colors.greenAccent[400],
                    borderRadius: 0,
                }}
            >
                <CardHeader
                    action={
                        <>
                            <Tabs
                                value={currentTabIndex}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab label="User Accounts" />
                                <Tab label="Custom Report" />
                            </Tabs>
                        </>
                    }
                />
                <Divider />

                <CardContent>
                    {/* ***************** Tab 0: All user accounts audit trail ******************** */}
                    {currentTabIndex === 0 && (
                        <>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <Box
                                        marginTop={2}
                                        marginBottom={2}
                                        sx={{
                                            bgcolor: colors.primary[400],
                                        }}
                                    >
                                        <Autocomplete
                                            style={{ width: "50%" }}
                                            disableClearable
                                            onChange={handleChange}
                                            value={selectedUser}
                                            options={users}
                                            getOptionLabel={(option) =>
                                                option.selectItem
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    label="Search or select a user to view their activity log"
                                                    {...params}
                                                    variant="standard"
                                                />
                                            )}
                                            sx={{ paddingLeft: "10px" }}
                                        ></Autocomplete>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />{" "}
                            {/* *****************AuditTrail Body******************** */}
                            <Box
                                m="0 0 0 0"
                                height="70vh"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                    },
                                    "& .name-column--cell": {
                                        color: colors.greenAccent[300],
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: colors.blueAccent[700],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor: colors.primary[400],
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        borderTop: "none",
                                        backgroundColor: colors.blueAccent[700],
                                    },
                                    "& .MuiCheckbox-root": {
                                        color: `${colors.greenAccent[200]} !important`,
                                    },
                                }}
                            >
                                {userLog.length > 0 && (
                                    <DataGrid
                                        rows={userLog}
                                        columns={columns}
                                        components={{
                                            Toolbar: CustomToolbar,
                                        }}
                                        // slots={{ toolbar: GridToolbar }}
                                        // checkboxSelection
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 10,
                                                },
                                            },
                                        }}
                                        pageSizeOptions={[5]}
                                        disableRowSelectionOnClick
                                        disableColumnMenu
                                        // disableColumnFilter
                                        // disableColumnSelector
                                        // CheckboxSelection
                                        // checkboxselection
                                    />
                                )}

                                {userLog.length === 0 && selectedUser && (
                                    <Typography variant="h6">
                                        No activity log found for this user
                                    </Typography>
                                )}
                            </Box>
                        </>
                    )}
                    {/* ***************** Tab 1: Charts ******************** */}
                    {currentTabIndex === 1 && <CustomReports />}
                </CardContent>
            </Card>
        </Box>
    );
}
