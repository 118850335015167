import { useState, useEffect, useContext } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import logo from "./logo.png";
import axiosClient from "../../axios";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import NoteIcon from "@mui/icons-material/Note";
import ArchiveIcon from "@mui/icons-material/Archive";
import PendingIcon from "@mui/icons-material/Pending";
import ListIcon from "@mui/icons-material/List";
import UserContext from "../../contexts/UserContext";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import SyncIcon from "@mui/icons-material/Sync";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AddchartIcon from "@mui/icons-material/Addchart";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import SummarizeIcon from "@mui/icons-material/Summarize";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import PaymentIcon from '@mui/icons-material/Payment';

const Item = ({ title, to, icon, selected, setSelected, newTab }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{
                color: colors.grey[100],
            }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{title}</Typography>
            <Link
                to={to}
                target={newTab ? "_blank" : undefined}
                rel={newTab ? "noopener noreferrer" : undefined}
            />
        </MenuItem>
    );
};

const Sidebar = ({ getIsLoginFlag }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState("Dashboard");

    // UserContext Provider
    const { token, user } = useContext(UserContext);

    // Create a useEffect to check if token exists and run on every render so that if the token does not exists, it will redirect to login page
    let navigate = useNavigate();
    useEffect(() => {
        if (!token || !user) {
            getIsLoginFlag(true);
            navigate("/login");
        }
    }, [token, user]);

    return (
        <Box
            sx={{
                "& .pro-sidebar-inner": {
                    background: `${colors.primary[400]} !important`,
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: "transparent !important",
                },
                "& .pro-inner-item": {
                    padding: "5px 35px 5px 20px !important",
                },
                "& .pro-inner-item:hover": {
                    color: "#868dfb !important",
                },
                "& .pro-menu-item.active": {
                    color: "#6870fa !important",
                },
            }}
        >
            <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                    {/* LOGO AND MENU ICON */}
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            margin: "10px 0 20px 0",
                            color: colors.grey[100],
                        }}
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="left"
                                ml="15px"
                            >
                                <img
                                    alt="OCCUSURV"
                                    width="150px"
                                    height="30px"
                                    src={logo}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                />
                                <IconButton
                                    onClick={() => setIsCollapsed(!isCollapsed)}
                                >
                                    <MenuOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                        <Item
                            title="Survey Schedule"
                            to="/calendar"
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        <Item
                            title="Risk Dashboard"
                            to="/dashboard"
                            icon={<DashboardIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        <Item
                            title="Fire Loss Reports"
                            to="/fire_loss_reports"
                            icon={<LocalFireDepartmentIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: "15px 0 5px 20px" }}
                        >
                            Surveys
                        </Typography>
                        <Item
                            title="Load New Surveys"
                            to="/load_surveys"
                            icon={<SyncIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        {user?.role != "Admin" && (
                            <Item
                                title="Requirements"
                                to="/requirements"
                                icon={
                                    <div style={{ transform: "rotate(90deg)" }}>
                                        {" "}
                                        <AddchartIcon />{" "}
                                    </div>
                                } // Rotate the icon to right(90deg)
                                selected={selected}
                                setSelected={setSelected}
                            />
                        )}
                        {user?.role == "Admin" && (
                            <Item
                                title="Invoiceable Surveys"
                                to="/invoiceable_surveys"
                                icon={<PaymentIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        )}
                        {user?.role == "Admin" && (
                            <Item
                                title="All Surveys"
                                to="/all_inspections"
                                icon={<FormatAlignJustifyIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        )}
                        <Item
                            title="Reports"
                            to="/inspections_report"
                            icon={<SummarizeIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Request New Survey"
                            to="https://occusurv.co.za/appointment-form/"
                            icon={<CreateNewFolderIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            newTab={true}
                        />
                        {user?.role == "Admin" && (
                            <Item
                                title="Templates"
                                to="/templates"
                                icon={<NoteIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        )}

                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: "15px 0 5px 20px" }}
                        >
                            System Users
                        </Typography>
                        <Item
                            title="Audit Trail"
                            to="/audit_trail"
                            icon={<BackupTableIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        {user?.role != "Underwriter" && (
                            <Item
                                title="List users"
                                to="/list_users"
                                icon={<PeopleAltIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        )}
                        {user?.role != "Underwriter" && (
                            <Item
                                title="New User"
                                to="/add_user"
                                icon={<GroupAddIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        )}
                        {user?.role === "Admin" && (
                            <Item
                                title="Insurances"
                                to="/insurances"
                                icon={<EnhancedEncryptionIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        )}
                        {user?.role === "Admin" && (
                            <Item
                                title="Upload Fire Loss"
                                to="/fire_loss_upload"
                                icon={<CloudUploadIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        )}
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    );
};

export default Sidebar;
