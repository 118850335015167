import { useState, useEffect } from "react";
import { useTheme, Box, Typography } from "@mui/material";
import { tokens } from "../../theme";
import {
    Document,
    Text,
    Page,
    View,
    Image,
    StyleSheet,
} from "@react-pdf/renderer";
import prettyDate from "../../formatdate";
import axiosClient from "../../axios";

export default function ViewPDF({ inspection, requirements }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [template, setTemplate] = useState(null);

    let loopCount = 1;

    useEffect(() => {
        async function getTemplate() {
            const response = await axiosClient.get(
                `/templates/get_template/${inspection.template_id}`
            );
            if (response.data) {
                // console.log("Response: ", response.data.template_name);
                setTemplate(response.data.template_name);
            }
        }
        getTemplate();
    }, [inspection]);

    const styles = StyleSheet.create({
        page: {
            fontFamily: "Helvetica",
            fontSize: 11,
            paddingTop: 30,
            paddingBottom: 30,
            paddingLeft: 60,
            paddingRight: 60,
            lineHeight: 1.5,
            flexDirection: "column",
        },
        logo: {
            width: 150,
            height: 30,
        },
        // ********************************* Survey Details *********************************
        titleContainer: {
            backgroundColor: colors.grey[900],
            flexDirection: "row",
            marginTop: 15,
            marginBottom: 30,
        },
        reportTitle: {
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 5,
            letterSpacing: 1,
            fontSize: 12,
            textTransform: "uppercase",
        },
        surveyItem: {
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            marginBottom: 10,
        },
        surveyItemTitle: {
            flex: "0 0 60%",
            fontWeight: "bold",
        },
        surveyItemValue: {
            flex: "0 0 40%",
            color: colors.grey[600],
        },
        hr: {
            backgroundColor: colors.grey[600],
            border: "0.5px solid " + colors.grey[700],
            marginBottom: 10,
        },
        // ********************************* Requirements *********************************
        requirementsContainer: {
            display: "flex",
            marginTop: 24,
        },
        tableStyle: {
            display: "table",
            width: "auto",
        },
        tableRowStyle: {
            flexDirection: "row",
        },
        noColHeaderStyle: {
            width: "5%",
            borderStyle: "solid",
            borderColor: "#000",
            borderBottomColor: "#000",
            borderWidth: 1,
            backgroundColor: colors.grey[900],
        },
        detailsColHeaderStyle: {
            width: "60%",
            borderStyle: "solid",
            borderColor: "#000",
            borderBottomColor: "#000",
            borderWidth: 1,
            borderLeftWidth: 0,
            backgroundColor: colors.grey[900],
        },
        statusColHeaderStyle: {
            width: "20%",
            borderStyle: "solid",
            borderColor: "#000",
            borderBottomColor: "#000",
            borderWidth: 1,
            borderLeftWidth: 0,
            backgroundColor: colors.grey[900],
        },
        dateColHeaderStyle: {
            width: "15%",
            borderStyle: "solid",
            borderColor: "#000",
            borderBottomColor: "#000",
            borderWidth: 1,
            borderLeftWidth: 0,
            backgroundColor: colors.grey[900],
        },
        noTableColStyle: {
            width: "5%",
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            borderTopWidth: 0,
        },
        detailsTableColStyle: {
            width: "60%",
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        statusTableColStyle: {
            width: "20%",
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        dateTableColStyle: {
            width: "15%",
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tableCellHeaderStyle: {
            textAlign: "center",
            margin: 4,
            fontSize: 12,
            fontWeight: "bold",
        },
        tableCellStyle: {
            margin: 5,
            fontSize: 10,
        },
    });

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={{ marginTop: 10, flexDirection: "column" }}>
                    <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                        {inspection.audit_insurer_name}
                    </Text>
                    <Text style={{ fontSize: 14}}>
                        {template}
                    </Text>
                    <Text style={{ alignSelf: "flex-end" }}>
                        Generated on: {prettyDate(new Date().toISOString())}
                    </Text>
                </View>

                <View style={styles.titleContainer}>
                    <Text style={styles.reportTitle}>
                        Completed requirements for the survey:
                    </Text>
                </View>
                <View style={styles.surveyItem}>
                    <Text style={styles.surveyItemTitle}> Policy Number:</Text>
                    <Text style={styles.surveyItemValue}>
                        {" "}
                        {inspection.audit_policy_number}
                    </Text>
                </View>
                <View style={styles.hr}></View>
                <View style={styles.surveyItem}>
                    <Text style={styles.surveyItemTitle}> Policy Holder: </Text>
                    <Text style={styles.surveyItemValue}>
                        {" "}
                        {inspection.audit_policy_holder}
                    </Text>
                </View>
                <View style={styles.hr}></View>
                <View style={styles.surveyItem}>
                    <Text style={styles.surveyItemTitle}> Broker: </Text>
                    <Text style={styles.surveyItemValue}>
                        {" "}
                        {inspection.audit_broker_name}
                    </Text>
                </View>
                <View style={styles.hr}></View>
                <View style={styles.surveyItem}>
                    <Text style={styles.surveyItemTitle}>
                        {" "}
                        Date of Survey:{" "}
                    </Text>
                    <Text style={styles.surveyItemValue}>
                        {" "}
                        {prettyDate(inspection.audit_survey_date)}
                    </Text>
                </View>
                <View style={styles.hr}></View>
                <View style={styles.surveyItem}>
                    <Text style={styles.surveyItemTitle}> Location: </Text>
                    <Text style={styles.surveyItemValue}>
                        {" "}
                        {inspection.audit_physical_address}
                    </Text>
                </View>
                <View style={styles.hr}></View>

            {/* ***********************************************Requirements**************************************** */}
                <View style={styles.requirementsContainer}>
                    <Text
                        style={{
                            marginBottom: 5,
                            fontSize: 14,
                            fontWeight: "bold",
                        }}
                    >
                        List of requirements that were required to be
                        implemented:
                    </Text>
                    {/* **********************Table Header****************** */}
                    <View style={styles.tableRowStyle} fixed>
                        <View style={styles.noColHeaderStyle}>
                            <Text style={styles.tableCellHeaderStyle}>No</Text>
                        </View>

                        <View style={styles.detailsColHeaderStyle}>
                            <Text style={styles.tableCellHeaderStyle}>
                                Requirement Details
                            </Text>
                        </View>

                        <View style={styles.statusColHeaderStyle}>
                            <Text style={styles.tableCellHeaderStyle}>
                                Status
                            </Text>
                        </View>
                        <View style={styles.dateColHeaderStyle}>
                            <Text style={styles.tableCellHeaderStyle}>
                                Completed
                            </Text>
                        </View>
                    </View>
                    {/* **********************Table Rows****************** */}
                    {requirements.map((requirement) => (
                        requirement.requirement_status == "Completed" && (
                        <View
                            style={styles.tableRowStyle}
                            key={requirement.id.toString()}
                            wrap={false}
                        >
                            <View style={styles.noTableColStyle}>
                                <Text style={styles.tableCellStyle}>
                                    {" "}
                                    {loopCount++}{" "}
                                </Text>
                            </View>

                            <View style={styles.detailsTableColStyle}>
                                <Text style={styles.tableCellStyle}>
                                    {" "}
                                    {requirement.requirement_details}{" "}
                                </Text>
                            </View>

                            <View style={styles.statusTableColStyle}>
                                <Text style={styles.tableCellStyle}>
                                    {" "}
                                    {requirement.requirement_status}{" "}
                                </Text>
                            </View>

                            <View style={styles.dateTableColStyle}>
                                <Text style={styles.tableCellStyle}>
                                    {requirement.checked == 1 && (prettyDate(requirement.updated_at))}
                                </Text>
                            </View>
                        </View>
                        )
                    ))}
                </View>
            </Page>
        </Document>
    );
}
