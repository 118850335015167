import {
    Box,
    IconButton,
    Typography,
    Button,
    TextField,
    Select,
    useTheme,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import UserContext from "../../contexts/UserContext";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import PasswordIcon from "@mui/icons-material/Password";
import Logout from "@mui/icons-material/Logout";
import axiosClient from "../../axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { set } from "lodash";

const Topbar = ({ getIsLoginFlag }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const { user, deleteToken } = useContext(UserContext);
    const [insurer, setInsurer] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [match, setMatch] = useState(true);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("success");

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let navigate = useNavigate();

    const handleLogout = () => {
        async function logout() {
            const response = await axiosClient
                .post("/user/logout", {})
                .then((response) => {
                    // console.log("RESPONSE: ", response);
                    if (response.data) {
                        getIsLoginFlag(true);
                        // Detele token & user from local storage
                        deleteToken();
                        // console.log(response.data.message);
                        navigate("/login");
                    } else {
                        console.log("Error while logging out");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        logout();
    };

    useEffect(() => {
        // If user is not empty, get insurer name
        if (Object.keys(user).length !== 0) {
            async function getInsurer() {
                const response = await axiosClient.get(
                    `insurer/${user?.insurer_id}`
                );
                // console.log("Insurer: ", response.data.insurer_name);
                setInsurer(response.data.insurer_name);
            }
            getInsurer();
        }
    }, [insurer]);

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleFormSubmit = (values) => {
        setMatch(true);
        if (values.newPassword !== values.confirmPassword) {
            setMatch(false);
            return;
        }
        async function changePassword() {
            const response = await axiosClient
                .put(`/user/change_password/${user.id}`, {
                    current_password: values.password,
                    new_password: values.newPassword,
                })
                .then((response) => {
                    // console.log("RESPONSE: ", response.data);
                    if (response.data) {
                        handleCloseDialog();
                        // If response code is 201, return as a string from API
                        if (response.data.code === "201") {
                            setAlertType("success");
                            setAlertMessage(response.data.message);
                            setSnackBarOpen(true);
                            // Detele token & user from local storage
                            deleteToken();
                            // console.log(response.data.message);
                            navigate("/login");
                        }
                    } else {
                        console.log("Error while changing password");
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setAlertType("error");
                    setAlertMessage(error.response.data.message);
                    setSnackBarOpen(true);
                });
        }
        changePassword();
    };

    const closeSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarOpen(false);
    };

    return (
        <Box display="flex" justifyContent="flex-end" p={2}>
            {/* ICONS */}
            <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === "dark" ? (
                        <DarkModeOutlinedIcon />
                    ) : (
                        <LightModeOutlinedIcon />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleClick}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                >
                    <PersonOutlinedIcon />
                    <Typography
                        variant="h6"
                        color={colors.grey[100]}
                        fontWeight="bold"
                    >
                        {user != null ? user.name : ""}
                    </Typography>
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem onClick={handleClose}>
                    <Box textAlign="center">
                        <Typography
                            variant="h6"
                            color={colors.grey[100]}
                            fontWeight="bold"
                            sx={{ m: "10px 0 0 0" }}
                        >
                            {/* Display logged username */}
                            {user != null ? user.name : ""}
                        </Typography>
                        <Typography
                            variant="h6"
                            color={colors.greenAccent[500]}
                        >
                            {user?.role == "Underwriter" ||
                            user?.role == "Executive"
                                ? insurer
                                : "Occusuv"}
                        </Typography>
                    </Box>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClickOpenDialog}>
                    <ListItemIcon>
                        <PasswordIcon fontSize="small" />
                    </ListItemIcon>
                    Change Password
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
            {/* *************************************DIALOG************************************* */}
            <div>
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">
                        <Typography
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                            Change your password
                        </Typography>
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <DialogContentText id="scroll-dialog-description">
                            {/* ***********Display Change Password Form *************** */}
                            <Formik
                                onSubmit={handleFormSubmit}
                                initialValues={initialValues}
                                validationSchema={checkoutSchema}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Box display="grid" width="300px">
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label="Current Password"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.password}
                                                name="password"
                                                error={
                                                    !!touched.password &&
                                                    !!errors.password
                                                }
                                                helpertext={
                                                    touched.password &&
                                                    errors.password
                                                }
                                                sx={{ gridColumn: "span 2" }}
                                            />

                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label="New Password"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.newPassword}
                                                name="newPassword"
                                                error={
                                                    !!touched.newPassword &&
                                                    !!errors.newPassword
                                                }
                                                helpertext={
                                                    touched.newPassword &&
                                                    errors.newPassword
                                                }
                                                sx={{ gridColumn: "span 2" }}
                                            />

                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label="Confirm New Password"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.confirmPassword}
                                                name="confirmPassword"
                                                error={
                                                    !!touched.confirmPassword &&
                                                    !!errors.confirmPassword
                                                }
                                                helpertext={
                                                    touched.confirmPassword &&
                                                    errors.confirmPassword
                                                }
                                                sx={{ gridColumn: "span 2" }}
                                            />
                                        </Box>

                                        <Box
                                            display="flex"
                                            justifyContent="end"
                                            mt="20px"
                                        >
                                            <Button
                                                type="submit"
                                                color="secondary"
                                                variant="contained"
                                                // disabled={!Formik.dirty}
                                            >
                                                Change Password
                                            </Button>
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontWeight: "bold",
                                                color: "red",
                                            }}
                                        >
                                            {/* If match is equal to false print passwords do not match */}
                                            {!match &&
                                                "Passwords do NOT match!"}
                                        </Typography>
                                    </form>
                                )}
                            </Formik>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Close</Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Snackbar
                open={snackBarOpen}
                autoHideDuration={6000}
                onClose={closeSnackbar}
            >
                <Alert
                    onClose={closeSnackbar}
                    severity={alertType}
                    color={alertType}
                    sx={{ width: "100%" }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

const checkoutSchema = yup.object().shape({
    password: yup.string().required("required"),
    newPassword: yup.string().required("required"),
    confirmPassword: yup.string().required("required"),
});

const initialValues = {
    password: "",
    newPassword: "",
    confirmPassword: "",
};

export default Topbar;
