import { useState, useEffect, useContext } from "react";
import { formatDate } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {
    Box,
    List,
    ListItem,
    ListItemText,
    Typography,
    useTheme,
} from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import UserContext from "../../contexts/UserContext";
import axiosClient from "../../axios";

const Calendar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const API_KEY = process.env.REACT_APP_TRELLO_API_KEY;
    const API_TOKEN = process.env.REACT_APP_TRELLO_API_TOKEN;

    const { user } = useContext(UserContext);
    const [userEmails, setUserEmails] = useState([]);
    const [cards, setCards] = useState([]);

    useEffect(() => {
      async function getUsers() {
        const response = await axiosClient.get(`/user/insurer/${user.insurer_id}`);
        if (response.data) {
            // console.log("All Users: ", response.data);
            // response.data, get user.email and save them in an array
            let userEmails = response.data.map((user) => user.email);
            // console.log("User Emails: ", userEmails);
            setUserEmails(userEmails);
        }
      }
        getUsers();
    }, []);
    // Get all boards for the user
    useEffect(() => {
      if (user.role == 'Admin') return;
      
      fetch(`https://api.trello.com/1/members/me/boards?fields=name,url&key=${API_KEY}&token=${API_TOKEN}`)
        .then(response => response.json())
        .then(boards => {
          // Fetch data for each board
          const boardDataPromises = boards.map(board =>
            fetch(`https://api.trello.com/1/boards/${board.id}?fields=name&cards=all&card_fields=name&customFields=true&card_customFieldItems=true&key=${API_KEY}&token=${API_TOKEN}`)
              .then(response => response.json())
          );

          Promise.all(boardDataPromises).then(boardDataArray => {
            const boardsWithCustomFields = boardDataArray.map(boardData => {
              // Create a map of custom field IDs to names
              const customFieldNames = boardData.customFields.reduce((map, field) => {
                map[field.id] = field.name;
                return map;
              }, {});

              // Add custom fields to each card
              boardData.cards = boardData.cards.map(card => {
                const customFields = card.customFieldItems.reduce((map, item) => {
                  // Only include the custom fields with the specified keys
                  const fieldName = customFieldNames[item.idCustomField];
                  if (['Appointment received by:', 'Email address:', 'Survey date:', 'Province:', 'Country:'].includes(fieldName)) {
                    if (fieldName === 'Survey date:') {
                      map[fieldName] = item.value.date;
                      // console.log("SURVEY DATE: ", item.value.date);
                    } else {
                      map[fieldName] = item.value.text;
                    }
                  }
                  return map;
                }, {});

                return { ...card, customFields };
              }).filter(card => {
                // Only include cards that have all the specified custom fields and 'Survey date:' is not undefined
                const keys = Object.keys(card.customFields);
                return ['Appointment received by:', 'Email address:', 'Survey date:', 'Province:', 'Country:'].every(key => keys.includes(key));
              });

              return boardData;
            });

            // console.log("BOARDS WITH CUSTOM FIELDS: ", boardsWithCustomFields);
            // Extract Custom Fields, id, and name
            let today = new Date();
            today.setHours(0, 0, 0, 0); // set time to 00:00:00

            // let extractedData = boardsWithCustomFields.map(board => {
            //   return board.cards
            //   // Filter cards to include only those with a survey date greater than or equal to today
            //     .filter(card => {
            //       let surveyDate = new Date(card.customFields['Survey date:']);
            //       surveyDate.setHours(0, 0, 0, 0); // set time to 00:00:00
            //       return surveyDate >= today;
            //     })
            //     .map(card => {
            //       return {
            //         customFields: card.customFields,
            //         id: card.id,
            //         name: card.name
            //       };
            //     });
            // }).flat();
            let extractedData = boardsWithCustomFields.map(board => {
              return board.cards.map(card => {
                return {
                  customFields: card.customFields,
                  id: card.id,
                  name: card.name
                };
              });
            }).flat();
            // Sort the extractedData array in ascending order by the 'Survey date:' property
            extractedData.sort((a, b) => {
              let dateA = new Date(a.customFields['Survey date:']);
              let dateB = new Date(b.customFields['Survey date:']);
              return dateA - dateB;
            });
            // console.log("Extracted DATA: ",extractedData);
            // setCards(extractedData);
            // Map the extractedData array to the structure expected by FullCalendar
            let prepareEvents = extractedData
              // .filter(card => card.customFields['Email address:'] === 'yolaneh@lombardins.com')
              // .filter(card => card.customFields['Email address:'] === user.email )
              .filter(card =>
                user.role === 'Underwriter' ? card.customFields['Email address:'] === user.email : userEmails.includes(card.customFields['Email address:'])
              )
              .map(card => {
              let dateTime = new Date(card.customFields['Survey date:']);
              let dateOnly = dateTime.toISOString().slice(0, 10); // convert to date-only string in 'yyyy-mm-dd' format
              return {
                  id: card.id,
                  title: card.name + ' - ' + card.customFields['Email address:'],
                  date: dateOnly,
                  // email: card.customFields['Email address:'],
              };
            });
            // console.log("Final Cards: ", prepareEvents);
            setCards(prepareEvents);
      });
      })
        .catch(err => console.error(err));
    }, [userEmails]);

    return (
        <Box m="20px">
            <Header title="CALENDER" subtitle="Survey Schedule" />

            <Box display="flex" justifyContent="space-between">
                {/* CALENDAR */}
                <Box flex="1 1 100%" ml="15px">
                    <FullCalendar
                        height="75vh"
                        plugins={[
                            dayGridPlugin,
                            timeGridPlugin,
                            interactionPlugin,
                            listPlugin,
                        ]}
                        headerToolbar={{
                            left: "prev,next today",
                            center: "title",
                            right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                        }}
                        initialView="dayGridMonth"
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        events={cards}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default Calendar;
