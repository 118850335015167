import { Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Document, Text, Page, View, StyleSheet, Font } from "@react-pdf/renderer";
import prettyDate from "../../formatdate";
import { compareAsc, format } from "date-fns";

export default function DownloadPDF({ titleItem, userLog, byUser }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    Font.register({ family: 'Helvetica' });
    const styles = StyleSheet.create({
        page: {
            fontFamily: "Helvetica",
            fontSize: 11,
            paddingTop: 30,
            paddingBottom: 30,
            paddingLeft: 60,
            paddingRight: 60,
            lineHeight: 1.5,
            flexDirection: "column",
        },
        // ********************************* Header Details *********************************
        titleContainer: {
            backgroundColor: colors.grey[900],
            flexDirection: "row",
            marginTop: 15,
            // marginBottom: 15,
        },
        logTitle: {
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 5,
            letterSpacing: 1,
            fontSize: 12,
        },
        // ********************************* Requirements *********************************
        logContainer: {
            display: "flex",
            marginTop: 24,
        },
        tableStyle: {
            display: "table",
            width: "auto",
        },
        tableRowStyle: {
            flexDirection: "row",
        },
        dateTableColStyle: {
            width: "20%",
        },
        detailsTableColStyle: {
            width: "60%",
        },
        ipTableColStyle: {
            width: "20%",
        },
        tableCellStyle: {
            margin: 5,
            fontSize: 10,
        },
    });

    // function renderDescriptionSegments(description) {
    //     const segments = description.split('(Completed).').map((segment, index, array) => {
    //         // Add '(Completed).' back to each segment except the last, and trim
    //         return (index < array.length - 1) ? `${segment.trim()}(Completed).\n\n` : segment.trim();
    //     }).filter(segment => segment !== ''); // Filter out any empty strings

    //     // Join all segments into a single string with line breaks between them
    //     const formattedDescription = segments.join('');
    //     console.log(formattedDescription);

    //     // Render the formatted description in a single <Text> component
    //     return (
    //         <Text>
    //             {formattedDescription}
    //         </Text>
    //     );
    // }
    // function renderDescriptionSegments(description) {
    //     const splitStrings = ['(Not selected)', '(Pending)', '(In progress)', '(To be attended)', '(Completed)', '(Archived)'];
    //     const regexPattern = splitStrings.join('|');
    //     const regex = new RegExp(regexPattern);

    //     if (!regex.test(description)) {
    //         return <Text>{description}</Text>;
    //     }

    //     const matches = description.match(new RegExp(regexPattern, 'g')) || [];
    //     const segments = description.split(regex).reduce((acc, segment, index) => {
    //         const nextSplitString = matches[index] ? `${matches[index]}\n\n` : ''; // Always append nextSplitString if it exists
    //         if (segment) {
    //             const trimmedSegment = segment.trim();
    //             acc.push(`${trimmedSegment}${nextSplitString}`);
    //         } else if (nextSplitString) {
    //             // If there's no segment but there is a nextSplitString, append it directly
    //             acc.push(nextSplitString);
    //         }
    //         return acc;
    //     }, []);

    //     const formattedDescription = segments.join('');
    //     console.log(formattedDescription);

    //     return (
    //         <Text>
    //             {formattedDescription}
    //         </Text>
    //     );
    // }
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={{ marginTop: 10, flexDirection: "column" }}>
                    <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                        Occusurv portal
                    </Text>
                    <Text style={{ alignSelf: "flex-end" }}>
                        Generated on: {prettyDate(new Date().toISOString())}
                    </Text>
                </View>

                <View style={styles.titleContainer}>
                    <Text style={styles.logTitle}>
                        {/* If byUser is true */}
                        {byUser
                            ? `Audit trail for: ${titleItem?.selectItem}`
                            : `Audit trail for broker: ${titleItem}`
                        }
                    </Text>
                </View>

                {/* ***********************************************Log**************************************** */}
                <View style={styles.logContainer}>
                    {userLog?.map((log) => (
                        <View
                            style={styles.tableRowStyle}
                            key={log.id.toString()}
                            wrap={false}
                        >
                            <View style={styles.dateTableColStyle}>
                                <Text style={styles.tableCellStyle}>
                                    {" "}
                                    {format(
                                        new Date(log?.created_at),
                                        "yyyy-MM-dd HH:mm"
                                    )}{" "}
                                </Text>
                            </View>

                            <View style={styles.detailsTableColStyle}>
                                <Text style={styles.tableCellStyle}>
                                    <Text style={{fontFamily: "Helvetica-Bold" }}>
                                        {log.title + ": "}
                                    </Text>
                                    <Text style={{ marginTop: 10 }}>
                                        {log.description}
                                        {/* {renderDescriptionSegments(log.description)} */}
                                    </Text>
                                </Text>
                            </View>

                            <View style={styles.ipTableColStyle}>
                                <Text style={styles.tableCellStyle}>
                                    {" "}
                                    {log.ip_address}{" "}
                                </Text>
                            </View>
                        </View>
                    ))}
                </View>
            </Page>
        </Document>
    );
}
