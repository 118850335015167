import {
    Typography,
    Box,
    useTheme,
    Grid,
    Paper,
    Button,
    TextField,
    Link,
} from "@mui/material";
import { Formik } from "formik";
import { tokens } from "../theme";
import React from "react";
import * as yup from "yup";
import logo from "./logo.png";
import axiosClient from "../axios";
import { useState, useEffect, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import UserContext from "../contexts/UserContext";

const Login = ( {getIsLoginFlag}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { token, user, saveToken } = useContext(UserContext);

    // Links with Side bar useEffect to redirect between Login and Dashboard
    let navigate = useNavigate();
    useEffect(() => {
        if (token) {
            // console.log("Navigating to home page");
            getIsLoginFlag(false);
            navigate("/calendar")
        } else {
            getIsLoginFlag(true);
            navigate("/login")
        }
    },[token]); // Run on every render

    const [error, setError] = useState(null);

    const paperStyle = {
        marginTop: 50,
        padding: 20,
        height: "55vh",
        width: 350,
    };

    const handleFormSubmit = (values) => {
        // console.log(values);
        const email = values.email;
        const password = values.password;

        axiosClient
            .post("user/login", {
                email: email,
                password: password,
            })
            .then(({ data }) => {
                // Save token and user info to local storage upon login
                saveToken(data.token, data.user);
                console.log("Setting isLogin to false");
                getIsLoginFlag(false);
                navigate("/calendar")
            })
            .catch((error) => {
                // setError(error.response.data.message);
                console.log("Thrown error: ", error);
                setError("Invalid username or password");
            });

        // If error is null, then the user has successfully logged in.
        if (error) {
            console.log("Error: ", error);
        }
    };

    return (
        <Box display="flex" margin="50px auto">
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box marginTop="10px">
                            <Paper elevation={10} style={paperStyle}>
                                <Grid align="center">
                                    <img
                                        alt="OCCUSURV"
                                        width="170px"
                                        height="30px"
                                        src={logo}
                                    />

                                    <h2>Sign In</h2>
                                </Grid>
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Username"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                    name="email"
                                    error={!!touched.email && !!errors.email}
                                    helperText={touched.email && errors.email}
                                    sx={{ mb: 3 }}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="password"
                                    label="Password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                    name="password"
                                    error={
                                        !!touched.password && !!errors.password
                                    }
                                    helperText={
                                        touched.password && errors.password
                                    }
                                    sx={{ mb: 3 }}
                                />
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    sx={{ margin: "8px 0", mb: 1 }}
                                >
                                    Sign in
                                </Button>
                                {/* If error during login, dispaly the error here */}
                                {error && (
                                    <Typography
                                        align="center"
                                        fontWeight="bold"
                                        color="error"
                                    >
                                        {error}
                                    </Typography>
                                )}
                                <Typography>
                                    <Link href="#">Forgot password?</Link>
                                </Typography>
                            </Paper>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

const checkoutSchema = yup.object().shape({
    email: yup.string().email("invalid email").required("required"),
    password: yup.string().required("required"),
});
const initialValues = {
    email: "",
    password: "",
};

export default Login;
