import { Box, Button, IconButton, Typography, useTheme,  Backdrop, CircularProgress, } from "@mui/material";
import { tokens } from "../../theme";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import TodayIcon from "@mui/icons-material/Today";
import SyncIcon from "@mui/icons-material/Sync";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import StatBox from "../../components/StatBox";
import { useState, useEffect } from "react";
import axiosClient from "../../axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { be } from "date-fns/locale";

export default function Statistics({ inspections_audits }) {
    // console.log("Statistics: ", inspections_audits);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [totalRequirements, setTotalRequirements] = useState(30);
    const [pending, setPending] = useState(0);
    const [inProgress, setInProgress] = useState(0);
    const [completed, setCompleted] = useState(0);
    const [beAttended, setBeAttended] = useState(0);
    const [byInspection, setByInspection] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [scroll, setScroll] = useState("paper");
    const [backdropOpen, setBackdropOpen] = useState(false);

    useEffect(() => {
        async function getReqStats() {
            setBackdropOpen(true);
            const response = await axiosClient.post(
                "/inspections/requirements/status_stats",
                {
                    inspections_audits,
                }
            );
            if (response.data) {
                // console.log("STATS: ", response.data);
                setTotalRequirements(response.data.total_requirements);
                setPending(response.data.pending);
                setInProgress(response.data.in_progress);
                setCompleted(response.data.completed);
                setBeAttended(response.data.to_be_attended);
                setByInspection(response.data.stats_by_inspection);
            }
            setBackdropOpen(false);
        }
        getReqStats();
    }, [inspections_audits]);
    // console.log("byInspection: ", byInspection);

    const handleClickOpen = (scrollType) => () => {
        setOpenDialog(true);
        setScroll("paper");
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <Box marginTop={2}>
            {/* GRID & CHARTS */}
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
            >
                {/* ROW 1 */}
                <Box
                    gridColumn="span 3"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    onClick={handleClickOpen()}
                    sx={{
                        cursor: "pointer",
                    }}
                >
                    <StatBox
                        title={pending}
                        subtitle={"Pending"}
                        // subtitle={
                        //     <Typography>
                        //     sx={{
                        //         color={colors.greenAccent[400]}
                        //     }}
                        //         Pending
                        //     </Typography>
                        // }
                        subtitleColor={colors.greenAccent[400]}
                        progress={pending / totalRequirements}
                        increase={
                            pending && totalRequirements
                                ? Math.ceil(
                                      (pending / totalRequirements) * 100
                                  ) + "%"
                                : "0%"
                        }
                        icon={
                            <MoreTimeIcon
                                sx={{
                                    color: colors.greenAccent[400],
                                    fontSize: "26px",
                                }}
                            />
                        }
                    />
                </Box>
                <Box
                    gridColumn="span 3"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    onClick={handleClickOpen()}
                    sx={{
                        cursor: "pointer",
                    }}
                >
                    <StatBox
                        title={inProgress}
                        subtitle="In progress"
                        progress={inProgress / totalRequirements}
                        increase={
                            inProgress && totalRequirements
                                ? Math.ceil(
                                      (inProgress / totalRequirements) * 100
                                  ) + "%"
                                : "0%"
                        }
                        icon={
                            <SyncIcon
                                sx={{
                                    color: colors.greenAccent[400],
                                    fontSize: "26px",
                                }}
                            />
                        }
                    />
                </Box>
                <Box
                    gridColumn="span 3"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    onClick={handleClickOpen()}
                    sx={{
                        cursor: "pointer",
                    }}
                >
                    <StatBox
                        title={beAttended}
                        subtitle="To be attended"
                        progress={beAttended / totalRequirements}
                        increase={
                            beAttended && totalRequirements
                                ? Math.ceil(
                                      (beAttended / totalRequirements) * 100
                                  ) + "%"
                                : "0%"
                        }
                        icon={
                            <TodayIcon
                                sx={{
                                    color: colors.greenAccent[400],
                                    fontSize: "26px",
                                }}
                            />
                        }
                    />
                </Box>
                <Box
                    gridColumn="span 3"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    onClick={handleClickOpen()}
                    sx={{
                        cursor: "pointer",
                    }}
                >
                    <StatBox
                        title={completed}
                        subtitle="Completed"
                        progress={completed / totalRequirements}
                        increase={
                            completed && totalRequirements
                                ? Math.ceil(
                                      (completed / totalRequirements) * 100
                                  ) + "%"
                                : "0%"
                        }
                        icon={
                            <TaskAltIcon
                                sx={{
                                    color: colors.greenAccent[400],
                                    fontSize: "26px",
                                }}
                            />
                        }
                    />
                </Box>
                {/* Render Backdrop */}
                <Backdrop
                        sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={backdropOpen}
                    >
                        <CircularProgress color="inherit" />
                </Backdrop>
            </Box>
            {/* *************************************DIALOG************************************* */}
            <div>
                <Dialog
                    open={openDialog}
                    onClose={handleClose}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">
                        <Typography
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                            Statistics of the status of surveys requirements
                        </Typography>
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        {/* <DialogContentText id="scroll-dialog-description"> */}
                            {/* ***********Display Table*************** */}
                            <TableContainer component={Paper}>
                                <Table
                                    // sx={{ minWidth: 650 }}
                                    aria-label="simple table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                align="left"
                                                sx={{ fontWeight: "bold" }}
                                            >
                                                Policy Holder
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                sx={{ fontWeight: "bold" }}
                                            >
                                                Pending
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                sx={{ fontWeight: "bold" }}
                                            >
                                                In&nbsp;Progress
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                sx={{ fontWeight: "bold" }}
                                            >
                                                To&nbsp;be&nbsp;Attended
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                sx={{ fontWeight: "bold" }}
                                            >
                                                Completed
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {byInspection.length > 0 &&
                                            byInspection.map((row) => (
                                                <TableRow
                                                    key={
                                                        row.audit_id
                                                    }
                                                    sx={{
                                                        "&:last-child td, &:last-child th":
                                                            { border: 0 },
                                                    }}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {
                                                            row.audit_policy_holder
                                                        }
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.pending}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.in_progress}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.to_be_attended}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.completed}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        {/* </DialogContentText> */}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Box>
    );
}
