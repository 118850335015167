import {
    Box,
    Grid,
    Button,
    Typography,
    useTheme,
    Backdrop,
    CircularProgress,
    Divider,
} from "@mui/material";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import axiosClient from "../../../axios";
import { React, useState, useEffect, useCallback } from "react";
import Stack from "@mui/material/Stack";
import DownloadIcon from "@mui/icons-material/Download";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import BarChart from "../../../components/BarChart";
import RefreshIcon from "@mui/icons-material/Refresh";
import { PDFDownloadLink } from "@react-pdf/renderer";
import IncompleteInspectionsReport from "./incompleteInspectionsReport";
import CompletedInspectionsReport from "./completionInspectionsReport";

export default function DetailedReports() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { RangePicker } = DatePicker;
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [completedInspections, setCompletedInspections] = useState([]);
    const [incompleteInspections, setIncompleteInspections] = useState([]);
    const [completedDatesRange, setCompletedDatesRange] = useState([]);
    const [incompleteDatesRange, setIncompleteDatesRange] = useState([]);
    const [barChartData, setBarChartData] = useState({});
    const [incompleteInspectionsReport, setIncompleteInspectionsReport] = useState({});
    const [completedInspectionsReport, setCompletedInspectionsReport] = useState({});
    const [averageCompletionDays, setAverageCompletionDays] = useState(0);

    const handleCompletedDateChange = (dates) => {
        setCompletedDatesRange(dates);
    };
    const handleIncompleteDateChange = (dates) => {
        setIncompleteDatesRange(dates);
    };

    const refreshCompletedInspections = () => {
        setBackdropOpen(true);
        async function getCompletedInspections() {
            const response = await axiosClient.get(
                `/inspections/completed_insurer_inspections/${dayjs(
                    completedDatesRange?.[0]
                ).format("YYYY-MM-DD")}/${dayjs(
                    completedDatesRange?.[1]
                ).format("YYYY-MM-DD")}`
            );

            if (response.data.length > 0) {
                setCompletedInspections(response.data);
                // Create start and end dates for the date range picker, first array element is start date, last is end date
                const startDate = dayjs(response.data[0].updated_at);
                const endDate = dayjs(
                    response.data[response.data.length - 1].updated_at
                );
                setCompletedDatesRange([startDate, endDate]);
            }

            setBackdropOpen(false);
        }
        getCompletedInspections();
    };
    const refreshIncompleteInspections = () => {
        setBackdropOpen(true);
        async function getIncompleteInspections() {
            const response = await axiosClient.get(
                `/inspections/detailed_reports_inspections/${dayjs(
                    incompleteDatesRange?.[0]
                ).format("YYYY-MM-DD")}/${dayjs(
                    incompleteDatesRange?.[1]
                ).format("YYYY-MM-DD")}`
            );

            if (response.data.length > 0) {
                setIncompleteInspections(response.data);
                // Create start and end dates for the date range picker, first array element is start date, last is end date
                const startDate = dayjs(response.data[0].reminder_start_date);
                const endDate = dayjs(
                    response.data[response.data.length - 1].reminder_start_date
                );
                setIncompleteDatesRange([startDate, endDate]);
            }
            setBackdropOpen(false);
        }
        getIncompleteInspections();
    };

    // ********************************* Completed Inspections updated ************************************************
    useEffect(() => {
        const auditsMonths = completedInspections.map((inspection) => {
            return {
                audit_id: inspection.audit_id,
                month: dayjs(inspection.reminder_start_date).format("MMM"),
            };
        });
        // console.log("AuditsMonths: ", auditsMonths);

        // Group auditsMonths by months such that you have variable groupedAuditsMonths as a list of objects with key as months and each object has a list of audit_id
        const groupedAuditsMonths = auditsMonths.reduce((acc, curr) => {
            const month = curr.month;
            if (!acc[month]) {
                acc[month] = [];
            }
            acc[month].push(curr.audit_id);
            return acc;
        }, {});
        // console.log("GroupedAuditsMonths: ", groupedAuditsMonths);

        // Now, get requirements statistics for a group of audits in each month
        async function getReqStats() {
            const reportsStats = {};
            for (const month in groupedAuditsMonths) {
                const response = await axiosClient.post(
                    "/inspections/requirements/status_stats",
                    {
                        inspections_audits: groupedAuditsMonths[month],
                    }
                );
                if (response.data) {
                    // Set the stats for each report
                    reportsStats[month] = response.data.stats_by_inspection;
                }
            }
            setCompletedInspectionsReport(reportsStats);
            // console.log("ReportsStats: ", reportsStats);
        }
        getReqStats();
    }, [completedInspections]);
    //  console.log("CompletedReports: ", completedInspectionsReport);

    // ********************************* Incomplete Inspections updated ************************************************
    useEffect(() => {
        // console.log("UPDATED Incomplete insp: ", incompleteInspections);
        // Loop through incomplete inspections and create a new array of objects with the required data for the bar chart
        const auditsMonths = incompleteInspections.map((inspection) => {
            return {
                audit_id: inspection.audit_id,
                month: dayjs(inspection.reminder_start_date).format("MMM"),
            };
        });
        // console.log("AuditsMonths: ", auditsMonths);

        // Group auditsMonths by months such that you have variable groupedAuditsMonths as a list of objects with key as months and each object has a list of audit_id
        const groupedAuditsMonths = auditsMonths.reduce((acc, curr) => {
            const month = curr.month;
            if (!acc[month]) {
                acc[month] = [];
            }
            acc[month].push(curr.audit_id);
            return acc;
        }, {});
        // console.log("GroupedAuditsMonths: ", groupedAuditsMonths);

        // Now, get requirements statistics for a group of audits in each month
        async function getReqStats() {
            const stats = [];
            const reportsStats = {};
            for (const month in groupedAuditsMonths) {
                const response = await axiosClient.post(
                    "/inspections/requirements/status_stats",
                    {
                        inspections_audits: groupedAuditsMonths[month],
                    }
                );
                if (response.data) {
                    // console.log(`Stats for ${month}: `, response.data);
                    // Set the stats for each month
                    stats.push({
                        month: month,
                        pending: response.data.pending,
                        inProgress: response.data.in_progress,
                        toBeAttended: response.data.to_be_attended,
                        completed: response.data.completed,
                    });

                    // Set the stats for each report
                    reportsStats[month] = response.data.stats_by_inspection;
                }
            }
            setBarChartData(stats);
            setIncompleteInspectionsReport(reportsStats);
            // console.log("ReportsStats: ", reportsStats);
        }
        getReqStats();
    }, [incompleteInspections]);
    // console.log("setIncompleteInspectionsReport: ", incompleteInspectionsReport);

    // ********************************* Average Completion Days ************************************************
    useEffect(() => {
        const totalDays = completedInspections.map((inspection) => {
            return dayjs(inspection.updated_at).diff(
                dayjs(inspection.reminder_start_date),
                "day"
            );
        });
        const averageDays =
            totalDays.reduce((a, b) => a + b, 0) / totalDays.length;
        setAverageCompletionDays(averageDays);
    }, [completedInspections]);
    return (
        <Box
            height="75vh"
        >
            <Header subtitle="Detailed reports on completed and incomplete requirements inspections" />
            <Grid container spacing={2} marginTop={1}>
                {/* ****************************** Completed Requirements Inspections ************************************* */}
                <Grid item xs={5}>
                    <Box>
                        <Stack spacing={1}>
                            <Typography variant="h5">
                                Completed requirements inspections reports
                            </Typography>
                            <Box sx={{ paddingBottom: 2 }}>
                                <RangePicker
                                    allowClear={false}
                                    value={
                                        completedDatesRange.length > 0
                                            ? completedDatesRange
                                            : ""
                                    }
                                    onChange={handleCompletedDateChange}
                                    showTime={false}
                                />
                                <Button>
                                    <RefreshIcon
                                        onClick={refreshCompletedInspections}
                                    />
                                </Button>
                            </Box>
                            <Typography>
                                Total Inspections: {completedInspections.length}{" "}
                            </Typography>
                            <Typography>
                                Average Completion Days:{" "}
                                {averageCompletionDays
                                    ? Math.round(averageCompletionDays)
                                    : 0}
                            </Typography>
                            {/* ********************************************************* DOWNLOADS ******************************************************************* */}
                            <Box>
                                {Object.keys(completedInspectionsReport)
                                    .length > 0 ? (
                                    <PDFDownloadLink
                                        document={
                                            <CompletedInspectionsReport
                                                inspectionsData={
                                                    completedInspectionsReport
                                                }
                                                datesRange={completedDatesRange}
                                            />
                                        }
                                        fileName={
                                            "Completed_inspections_report.pdf"
                                        }
                                    >
                                        <Button
                                            variant="contained"
                                            size="small"
                                            startIcon={<DownloadIcon />}
                                        >
                                            Download Report
                                        </Button>
                                    </PDFDownloadLink>
                                ) : (
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        startIcon={<DownloadIcon />}
                                        disabled
                                    >
                                        Download Report
                                    </Button>
                                )}
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
                <Divider orientation="vertical" flexItem />
                {/* ****************************** Incomplete Requirements Inspections ************************************* */}
                <Grid item xs={6}>
                    <Box>
                        <Stack spacing={1}>
                            <Typography variant="h5">
                                Incomplete requirements inspections reports
                            </Typography>
                            <Box sx={{ paddingBottom: 2 }}>
                                <RangePicker
                                    allowClear={false}
                                    value={
                                        incompleteDatesRange.length > 0
                                            ? incompleteDatesRange
                                            : ""
                                    }
                                    onChange={handleIncompleteDateChange}
                                    showTime={false}
                                />
                                <Button>
                                    <RefreshIcon
                                        onClick={refreshIncompleteInspections}
                                    />
                                </Button>
                            </Box>
                            <Typography>
                                Total Inspections:{" "}
                                {incompleteInspections.length}{" "}
                            </Typography>
                            {/* ************************ BAR CHART  ***************************** */}
                            <Box height="45vh" paddingTop={3}>
                                <Typography
                                    variant="subtitle1"
                                    textAlign={"center"}
                                >
                                    Bar Chart - Requirements status
                                </Typography>
                                <BarChart data={barChartData} />
                            </Box>
                            <Box>
                                {Object.keys(incompleteInspectionsReport)
                                    .length > 0 ? (
                                    <PDFDownloadLink
                                        document={
                                            <IncompleteInspectionsReport
                                                inspectionsData={
                                                    incompleteInspectionsReport
                                                }
                                                datesRange={
                                                    incompleteDatesRange
                                                }
                                            />
                                        }
                                        fileName={
                                            "Incompelete_inspections_report.pdf"
                                        }
                                    >
                                        <Button
                                            variant="contained"
                                            size="small"
                                            startIcon={<DownloadIcon />}
                                        >
                                            Download Report
                                        </Button>
                                    </PDFDownloadLink>
                                ) : (
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        startIcon={<DownloadIcon />}
                                        disabled
                                    >
                                        Download Report
                                    </Button>
                                )}
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}
