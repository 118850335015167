import {
    Box,
    useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { React } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import SummaryReports from "./reports/SummaryReports";
import DetailedReports from "./reports/DetailedReports";

const Inspection = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
        

    return (
        <Box m="20px">
            <Header
                title="INSPECTIONS"
                subtitle="Surveys and requirements reports"
            />

            <Card
                variant="outlined"
                sx={{
                    marginTop: "40px",
                    borderTop: 3,
                    borderTopColor: colors.greenAccent[400],
                    borderRadius: 0,
                }}
            >
                <CardContent>
                    <SummaryReports />
                    <DetailedReports />
                </CardContent>
            </Card>
        </Box>
    );
};

export default Inspection;
