import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axiosClient from "../../axios";
import { useState, useEffect } from "react";
import { compareAsc, format } from "date-fns";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import NewTemplate from "./NewTemplate";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AddIcon from "@mui/icons-material/Add";
import SyncIcon from "@mui/icons-material/Sync";
import { set } from "lodash";

const Template = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isNewTemplate, setIsNewTemplate] = useState(false);

    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("New Templates loaded successfully!");
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        setBackdropOpen(true);
        async function getTemplates() {
            const response = await axiosClient.get("templates");
            setTemplates(response.data);
            setBackdropOpen(false);
        }
        getTemplates();
    }, [isNewTemplate]);
    // console.log(templates);

    const newTemplate = () => {
        setIsNewTemplate(true);
    };

    const closeSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarOpen(false);
    };

    const syncInspections = () => {
        setBackdropOpen(true);
        async function getInspections() {
            const response = await axiosClient
                .post("inspections/store_next_inspection")
                .then((response) => {
                    // If response.data is not null, set the inspection state variable to the response.data
                    if (response.data) {
                        // console.log("Fetched Inspections: ", response.data);
                        setBackdropOpen(false);
                        setSnackBarMessage("Inspections synced successfully!");
                        setSnackBarOpen(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        getInspections();
    };

    const columns = [
        { field: "id", headerName: "ID" },
        {
            field: "template_name",
            headerName: "Template Name",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "total_inspections",
            headerName: "Total Inspections",
            flex: 1,
        },
        {
            field: "template_created_at",
            headerName: "Date Created",
            flex: 1,
            renderCell: ({ row: { template_created_at } }) => {
                const formattedDate = format(
                    new Date(template_created_at),
                    "yyyy-MM-dd HH:mm"
                );

                return <Typography>{formattedDate}</Typography>;
            },
        },
        {
            field: "template_modified_at",
            headerName: "Date Modified",
            flex: 1,
            renderCell: ({ row: { template_modified_at } }) => {
                const formattedDate = format(
                    new Date(template_modified_at),
                    "yyyy-MM-dd HH:mm"
                );
                return <Typography>{formattedDate}</Typography>;
            },
        },
    ];

    return (
        <Box m="20px">
            <Header title="TEMPLATES" subtitle="List of templates" />

            <Card
                variant="outlined"
                sx={{
                    marginTop: "40px",
                    borderTop: 3,
                    borderTopColor: colors.greenAccent[400],
                    borderRadius: 0,
                }}
            >
                <CardContent>
                    <Box
                        // m="40px 0 0 0"
                        mb="20px"
                        height="75vh"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: colors.blueAccent[700],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: colors.blueAccent[700],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                        }}
                    >
                        {!isNewTemplate && (
                            <Box display="flex" justifyContent="end" mb="10px">
                                <Button
                                    sx={{ marginRight: "10px" }}
                                    color="secondary"
                                    variant="contained"
                                    onClick={syncInspections}
                                    startIcon={<SyncIcon />}
                                >
                                    Sync Inspections
                                </Button>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={newTemplate}
                                    startIcon={<AddIcon />}
                                >
                                    New Template
                                </Button>
                            </Box>
                        )}

                        {isNewTemplate && (
                            <NewTemplate setIsNewTemplate={setIsNewTemplate} />
                        )}

                        <DataGrid rows={templates} columns={columns} />
                    </Box>
                    <Backdrop
                        sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={backdropOpen}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Snackbar
                        open={snackBarOpen}
                        autoHideDuration={6000}
                        onClose={closeSnackbar}
                    >
                        <Alert
                            onClose={closeSnackbar}
                            severity="success"
                            color="success"
                            sx={{ width: "100%" }}
                        >
                            {snackBarMessage}
                        </Alert>
                    </Snackbar>
                </CardContent>
            </Card>
        </Box>
    );
};

export default Template;
