import * as React from "react";
import {
    Box,
    Grid,
    Backdrop,
    CircularProgress,
    Button,
    Typography,
    useTheme,
    Divider,
    Tab,
    Tabs,
} from "@mui/material";
import { useState, useEffect } from "react";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import InspectionType from "./InspectionType";
import axiosClient from "../../axios";
import InspectionDetails from "./InspectionDetails";
import { set } from "lodash";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import prettyDate from "../../formatdate";
import Statistics from "./Statistics";
import { debounce } from "lodash";
import { DatePicker } from "antd";
import RefreshIcon from "@mui/icons-material/Refresh";
import dayjs from "dayjs";
import SingleSelect from "../../components/SingleSelect";
import RestartAltIcon from '@mui/icons-material/RestartAlt';

export default function Requirements() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { RangePicker } = DatePicker;
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [inspections, setInspections] = useState([]); // Send this to InspectionType (Parent to Child)
    const [filteredInspections, setFilteredInspections] = useState([]); 
    const [menuInspections, setMenuInspections] = useState([]);
    const [brokerList, setBrokerList] = useState([]); 
    const [statsInspections, setStatsInspections] = useState([]); // Send this to Statistics (Parent to Child)
    const [selectedInspection, setSelectedInspection] = useState(null); // Get this from InspectionType (Child to Parent)
    const [displayInspectionID, setDisplayInspectionID] = useState(null); // Send this to InspectionDetails (Parent to Child)
    const [refreshDatesRange, setRefreshDatesRange] = useState([]);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [selectedBroker, setSelectedBroker] = useState(null);

    const handleTabChange = (e, tabIndex) => {
        setInspections([]);
        setDisplayInspectionID(null);
        setCurrentTabIndex(tabIndex);
        setRefreshDatesRange([]);
    };

    const getInspections = debounce(async () => {
        setBackdropOpen(true);
        let api_path = "";
        if (currentTabIndex === 0) {
            api_path = "/inspections/active_inspections";
        } else if (currentTabIndex === 1) {
            api_path = "/inspections/completed_inspections";
        } else if (currentTabIndex === 2) {
            api_path = "/inspections/overdue_inspections";
        } else if (currentTabIndex === 3) {
            api_path = "/inspections/insurer_archived_inspections";
        }
        const response = await axiosClient.get(api_path);
        setInspections(response.data)
        setFilteredInspections(response.data); // For filtering
        // console.log("Response Data: ", response.data);
        setBackdropOpen(false);
    }, 500); // delay in ms

    useEffect(() => {
        getInspections();
    }, [currentTabIndex]);

    // Update the list of brokers based on inspections loaded
    useEffect(() => {
        // console.log("Updating filtered inspections")
        const broker_list = [];
        for (const key in inspections) {
            // Store broker names into broker_list
            broker_list.push(inspections[key].audit_broker_name);
        }
        // Create unique broker list
        const unique_brokers = [...new Set(broker_list)];
        const brokersObject = unique_brokers.map(broker => ({ label: broker, value: broker }));
        brokersObject.unshift({ label: 'All', value: 'All' });
        // console.log("Unique Brokers: ", brokersObject);
        setBrokerList(brokersObject);
    }, [inspections]);

    // Update filtered inspections based on date and broker
    useEffect(() => {
        // Loop through object of response.data and create a new array on inspections
        // console.log("Updating filtered inspections")
        const loaded_inspections = [];
        const inspections_audits = [];
        for (const key in filteredInspections) {
            // Store in loaded_inspections
            loaded_inspections.push({
                id: filteredInspections[key].id,
                selectItem:
                filteredInspections[key].audit_policy_holder +
                    " - " +
                    filteredInspections[key].audit_broker_name +
                    " - " +
                    prettyDate(filteredInspections[key].audit_survey_date),
            });
            // Store audit_ids into inspections_audits
            inspections_audits.push(filteredInspections[key].audit_id);
        }
        setMenuInspections(loaded_inspections);
        setStatsInspections(inspections_audits);
    }, [filteredInspections]);

    // Get Inspection ID from InspectionType (Child to Parent)
    const getSelectedInspection = (inspectionDetails) => {
        setSelectedInspection(inspectionDetails);
        setDisplayInspectionID(inspectionDetails.id);
    };
    // console.log("Inspection ID: ", selectedInspection);

    const handleRefreshdDateChange = (dates) => {
        setRefreshDatesRange(dates);
    };

    const refreshInspections = () => {
        if (refreshDatesRange.length === 0) {
            return;
        }
        setFilteredInspections([]);
        setMenuInspections([]);
        setStatsInspections([]);
        setSelectedBroker(null);
        // setAuditId("");
        // console.log("Inspections: ", inspections);
        const startDate = dayjs(refreshDatesRange?.[0]).format("YYYY-MM-DD");
        const endDate = dayjs(refreshDatesRange?.[1]).format("YYYY-MM-DD");
        // console.log("Start Date: ", startDate);
        // console.log("End Date: ", endDate);
        setBackdropOpen(true);
        // Filter inspections to be between the selected dates (startDate and endDate)
        let compareDate = currentTabIndex !== 3 ? 'reminder_start_date' : 'updated_at';
        // console.log("Compare Date: ", compareDate);
        const filterInspections = Object.values(inspections).filter(
            (inspection) => {
                const reminderDate = dayjs(inspection[compareDate]).format(
                    "YYYY-MM-DD"
                );
                return reminderDate >= startDate && reminderDate <= endDate;
            }
        );
        // console.log("Filtering Inspections: ", filterInspections);
        setFilteredInspections(filterInspections);
        setBackdropOpen(false);
    };

    // When the selectedBroker changes, filter inspections based on the selected broker
    useEffect(() => {
        // console.log("Selected Broker: ", selectedBroker);
        if (selectedBroker !== null) {
            if (selectedBroker === 'All') {
                setFilteredInspections(inspections);
            } else {
                const filterInspections = Object.values(inspections).filter(
                    (inspection) => {
                        return inspection.audit_broker_name === selectedBroker;
                    }
                );
                setFilteredInspections(filterInspections);
            }
        }
    }, [selectedBroker]);

    const resetFilters = () => {
        // console.log("Resetting filters");
        setRefreshDatesRange([]);
        setSelectedBroker(null);
        setFilteredInspections(inspections);
    }

    return (
        <Box m="20px">
            <Header
                title="REQUIREMENTS"
                subtitle="Keep track of completed and pending requirements"
            />
            <Card
                variant="outlined"
                sx={{
                    marginTop: "40px",
                    borderTop: 3,
                    borderTopColor: colors.greenAccent[400],
                    borderRadius: 0,
                }}
            >
                <CardContent>
                    <Tabs
                        value={currentTabIndex}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="Incomplete Requirements" />
                        <Tab label="Completed Requirements" />
                        <Tab label="Overdue Requirements" />
                        <Tab label="Archived Surveys" />
                    </Tabs>
                    {/* ************************************ Date Range and Broker filters **************************************** */}
                    <Box sx= {{ marginTop: 1 , display: "flex"}}>
                        <RangePicker
                            allowClear={false}
                            value={
                                refreshDatesRange.length >
                                0
                                    ? refreshDatesRange
                                    : ""
                            }
                            onChange={
                                handleRefreshdDateChange
                            }
                            showTime={false}
                        />
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<RefreshIcon />}
                            onClick={refreshInspections}
                            sx = {{ marginLeft: 2}}
                        >
                            Reload
                        </Button>
                        <Box sx={{ flexGrow: 1, maxWidth: '20%', marginLeft: 2}}>
                            <SingleSelect
                                options={brokerList}
                                // Find the array index of selectedBroker in brokers array and set it as selectedValueIndex
                                selectedValueIndex={brokerList?.findIndex(
                                    (broker) => broker?.value === selectedBroker
                                )}
                                onSelectedValue={setSelectedBroker}
                                placeholder="Select Broker..."
                            />
                        </Box>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<RestartAltIcon />}
                            onClick={resetFilters}
                            sx = {{ marginLeft: 2}}
                        >
                            Reset
                        </Button>
                    </Box>
                    {/* ************************************ Date Range and Broker filters **************************************** */}
                    {/* TAB 1 Contents - Incomplete Inspections */}
                    {currentTabIndex === 0 && (
                        <>
                            <Statistics inspections_audits={statsInspections} />
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <InspectionType
                                        inspections={menuInspections}
                                        getSelectedInspection={
                                            getSelectedInspection
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Divider />{" "}
                            <Box paddingBottom={2} sx={{ flexGrow: 1 }}>
                                <InspectionDetails
                                    inspectionID={displayInspectionID}
                                    currentIndexTab={currentTabIndex}
                                />
                            </Box>
                        </>
                    )}

                    {/* TAB 2 Contents - Complete Inspections */}
                    {currentTabIndex === 1 && (
                        <>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <InspectionType
                                        inspections={menuInspections}
                                        getSelectedInspection={
                                            getSelectedInspection
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Box>
                                <InspectionDetails
                                    inspectionID={displayInspectionID}
                                    currentIndexTab={currentTabIndex}
                                />
                            </Box>
                        </>
                    )}

                    {/* TAB 3 Contents - Overdue Requirements */}
                    {currentTabIndex === 2 && (
                        <>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <InspectionType
                                        inspections={menuInspections}
                                        getSelectedInspection={
                                            getSelectedInspection
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Box>
                                <InspectionDetails
                                    inspectionID={displayInspectionID}
                                    currentIndexTab={currentTabIndex}
                                />
                            </Box>
                        </>
                    )}

                    {/* TAB 4 Contents - Archived Inspections */}
                    {currentTabIndex === 3 && (
                        <>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <InspectionType
                                        inspections={menuInspections}
                                        getSelectedInspection={
                                            getSelectedInspection
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Box>
                                <InspectionDetails
                                    inspectionID={displayInspectionID}
                                    currentIndexTab={currentTabIndex}
                                />
                            </Box>
                        </>
                    )}

                    {/* Render Backdrop */}
                    <Backdrop
                        sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={backdropOpen}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </CardContent>
            </Card>
        </Box>
    );
}
