import React from "react";
import {
    Box,
    Button,
    Divider,
    Grid,
    Typography,
    useTheme,
} from "@mui/material";
import axiosClient from "../../axios";
import { useState, useEffect, useContext } from "react";
import { tokens } from "../../theme";
import UserContext from "../../contexts/UserContext";
import DownloadPDF from "./DownloadPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DownloadIcon from "@mui/icons-material/Download";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import { filter, set } from "lodash";
import Select from "react-select";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function CustomReports() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user } = useContext(UserContext);
    const [reportAudits, setReportAudits] = useState([]);
    const [insurerAudits, setInsurerAudits] = useState([]);
    const [brokerAudits, setBrokerAudits] = useState([]);
    const [brokerPolicyNumbers, setBrokerPolicyNumbers] = useState({});
    const [brokerEmails, setBrokerEmails] = useState([]);
    const [selectedBroker, setSelectedBroker] = useState();
    const [selectedPolicyNumber, setSelectedPolicyNumber] = useState();
    const [selectedBrokerEmail, setSelectedBrokerEmail] = useState();
    const [auditTitles, setAuditTitles] = useState({
        autoReminder: true,
        manualReminder: true,
        brokerUpdate: true,
        newSurvey: true,
        requirementUpdated: true,
    });

    const { autoReminder, manualReminder, brokerUpdate, newSurvey, requirementUpdated} = auditTitles;
    const titleValues = {
        autoReminder: "Automatic Reminder Email",
        manualReminder: "Manual Reminder Email",
        brokerUpdate: "Broker Requirements Update",
        newSurvey: "New Survey Uploaded",
        requirementUpdated : "Requirement Updated",
    };

    const handleAuditTitleChange = (event) => {
        setAuditTitles({
            ...auditTitles,
            [event.target.name]: event.target.checked,
        });
    };

    const handleChangeBroker = (selected) => {
        // console.log("Broker name: ", selected.value);
        setSelectedBroker(selected?.value);
    };

    const handleChangePolicyNumber = (selected) => {
        // console.log("Policy number: ", selected.value);
        setSelectedPolicyNumber(selected?.value);
    };

    const handleChangeBrokerEmail = (selected) => {
        // console.log("Broker email: ", selected.value);
        setSelectedBrokerEmail(selected?.value);
    };

    const handleGenerateReport = () => {
        // console.log("Broker Name: ", selectedBroker);
        // console.log("Policy Number: ", selectedPolicyNumber);
        // console.log("Broker Email: ", selectedBrokerEmail);
        // console.log("Audit Titles: ", auditTitles);

        // Check if all auditTitles are false and display window alert
        if (!auditTitles.autoReminder && !auditTitles.manualReminder && !auditTitles.brokerUpdate && !auditTitles.newSurvey && !auditTitles.requirementUpdated) {
            window.alert("Please select at least one audit trail title.");
            return;
        }

        let filterReportAudits = insurerAudits;
        // Filter reportAudits based on selectedBroker if not null
        if (selectedBroker) {
            filterReportAudits = insurerAudits.filter(
                (audit) =>
                    audit?.inspection?.["audit_broker_name"] === selectedBroker
            );
        }
        if (selectedPolicyNumber) {
            filterReportAudits = filterReportAudits.filter(
                (audit) =>
                    audit?.inspection?.["audit_policy_number"] ===
                    selectedPolicyNumber
            );
        }
        if (selectedBrokerEmail) {
            filterReportAudits = filterReportAudits.filter(
                (audit) =>
                    audit?.inspection?.["audit_broker_email"] ===
                    selectedBrokerEmail
            );
        }
        // Loop through auditTitles and filter reportAudits based on auditTitles set to true
        let titleAudits = [];
        Object.keys(auditTitles).forEach((title) => {
            if (auditTitles[title]) {
                const filteredAuditsWithTitle = filterReportAudits.filter(
                    (audit) => audit.title === titleValues[title]
                );
                titleAudits = titleAudits.concat(filteredAuditsWithTitle);

                // console.log("Title: ", title);
                // console.log("Filtered report audits: ", titleAudits);
            }
        });

        setReportAudits(titleAudits); // filterReportAudits
        // console.log("Report audits: ", reportAudits);
    };

    useEffect(() => {
        function compileBrokerEmails() {
            const brokerEmails = [];
            insurerAudits.forEach((audit) => {
                if (
                    audit?.inspection?.["audit_broker_name"] === selectedBroker &&
                    audit?.inspection?.["audit_policy_number"] ===
                        selectedPolicyNumber
                ) {
                    brokerEmails.push(audit.inspection["audit_broker_email"]);
                }
            });
            setBrokerEmails([...new Set(brokerEmails)]);
            // console.log("Broker emails: ", [...new Set(brokerEmails)]);
        }
        compileBrokerEmails();
    }, [selectedBroker, selectedPolicyNumber]);

    useEffect(() => {
        async function getInsurerAudits() {
            const response = await axiosClient.get(
                `/audit_trails/insurer/${user.insurer_id}}`
            );
            if (response.data) {
                // console.log("Insurer audits: ", response.data);
                setInsurerAudits(response.data);
                // Loop through array of response.data and read inspection dictionary audit_broker_name key and create an array out of this
                // Then use this array to populate the broker dropdown
                const brokerNames = response.data
                    .map((audit) => {
                        return audit?.inspection?.["audit_broker_name"] || "";
                    })
                    .filter(name => name !== "");
                // Make brokerNames unique
                const uniqueBrokerNames = [...new Set(brokerNames)];
                setBrokerAudits(uniqueBrokerNames);
                // console.log("Broker audits: ", uniqueBrokerNames);
                // From uniqueBrokerNames, create a dictionary with broker name as key and policy numbers as values read from inspection dictionary of response.data
                const bPolicyNumbers = {};
                uniqueBrokerNames.forEach((broker) => {
                    bPolicyNumbers[broker] = [];
                });
                // console.log("Broker policy numbers: ", bPolicyNumbers);
                // Loop through response.data and populate bPolicyNumbers dictionary with policy numbers where audit.inspection["audit_broker_name"] is the key
                response.data.forEach((audit) => {
                    if (audit?.inspection?.["audit_broker_name"]) {
                        bPolicyNumbers[audit.inspection["audit_broker_name"]].push(
                            audit.inspection["audit_policy_number"]
                        );
                    }
                });
                // Make values of bPolicyNumbers unique
                Object.keys(bPolicyNumbers).forEach((broker) => {
                    bPolicyNumbers[broker] = [
                        ...new Set(bPolicyNumbers[broker]),
                    ];
                });

                setBrokerPolicyNumbers(bPolicyNumbers);
                // console.log("Broker policy numbers: ", bPolicyNumbers);
            }
        }
        getInsurerAudits();
    }, []);

    const options = [
        { value: "chocolate", label: "Chocolate" },
        { value: "strawberry", label: "Strawberry" },
        { value: "vanilla", label: "Vanilla" },
    ];

    return (
        <Box marginBottom={3} height={"250px"}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Box
                        sx={{ position: "absolute", zIndex: 1, width: "500px" }}
                    >
                        <Box marginBottom={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="h6">
                                        Select Broker Name:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sx={{ width: "100%" }}>
                                    <Select
                                        onChange={handleChangeBroker}
                                        isClearable={true}
                                        options={brokerAudits.map((broker) => {
                                            return {
                                                value: broker,
                                                label: broker,
                                            };
                                        })}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box marginBottom={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="h6">
                                        Select Policy Number:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sx={{ width: "100%" }}>
                                    <Select
                                        // Change key when selectedBroker changes
                                        key={
                                            selectedBroker
                                                ? selectedBroker
                                                : "defaultKey"
                                        }
                                        onChange={handleChangePolicyNumber}
                                        isClearable={true}
                                        options={brokerPolicyNumbers[
                                            selectedBroker
                                        ]?.map((policy) => {
                                            return {
                                                value: policy,
                                                label: policy,
                                            };
                                        })}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box marginBottom={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="h6">
                                        Select Broker Email:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sx={{ width: "100%" }}>
                                    <Select
                                        // Change key when selectedBroker changes
                                        key={
                                            selectedPolicyNumber
                                                ? selectedPolicyNumber
                                                : "defaultKey"
                                        }
                                        onChange={handleChangeBrokerEmail}
                                        isClearable={true}
                                        options={brokerEmails.map((email) => {
                                            return {
                                                value: email,
                                                label: email,
                                            };
                                        })}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={6}>
                    <Box sx={{ display: "flex" }}>
                        <FormControl
                            // sx={{ m: 3 }}
                            component="fieldset"
                            variant="standard"
                        >
                            <FormLabel component="legend">
                                Select audit trail title:
                            </FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={autoReminder}
                                            onChange={handleAuditTitleChange}
                                            name="autoReminder"
                                        />
                                    }
                                    label="Automatic reminder emails"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={manualReminder}
                                            onChange={handleAuditTitleChange}
                                            name="manualReminder"
                                        />
                                    }
                                    label="Manual reminder emails"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={brokerUpdate}
                                            onChange={handleAuditTitleChange}
                                            name="brokerUpdate"
                                        />
                                    }
                                    label="Broker requirements updates"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={newSurvey}
                                            onChange={handleAuditTitleChange}
                                            name="newSurvey"
                                        />
                                    }
                                    label="New loaded surveys"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={requirementUpdated}
                                            onChange={handleAuditTitleChange}
                                            name="requirementUpdated"
                                        />
                                    }
                                    label="Updated requirements"
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
            {/* Generate report button */}
            {/* <Box sx={{ display: "flex", justifyContent: "flex-center" }}> */}
            <Grid container justifyContent="center" marginTop={3}>
                <Grid item xs={6} textAlign="end" padding={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<ManageHistoryIcon />}
                        onClick={handleGenerateReport}
                    >
                        Generate Report
                    </Button>
                </Grid>
                <Grid item xs={6} textAlign="start" padding={1}>
                    {reportAudits?.length > 0 ? (
                        <PDFDownloadLink
                            document={
                                <DownloadPDF
                                    titleItem={selectedBroker}
                                    userLog={reportAudits}
                                    byUser={false}
                                />
                            }
                            fileName={selectedBroker + "_log.pdf"}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<DownloadIcon />}
                            >
                                Download Report
                            </Button>
                        </PDFDownloadLink>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<DownloadIcon />}
                            disabled
                        >
                            Download Report
                        </Button>
                    )}

                </Grid>
            </Grid>
            {/* </Box> */}
        </Box>
    );
}
