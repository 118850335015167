import * as React from "react";
import {
    Box,
    Button,
    Typography,
    useTheme,
    Backdrop,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
    Divider,
    Stack,
    IconButton,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axiosClient from "../../axios";
import prettyDate from "../../formatdate";
import { useState, useEffect } from "react";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import NewSurvey from "./NewSurvey";
import ListItemButton from "@mui/material/ListItemButton";
import { DatePicker } from "antd";
import RefreshIcon from "@mui/icons-material/Refresh";
import dayjs from "dayjs";

const LoadSurvey = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { RangePicker } = DatePicker;
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [inspections, setInspections] = useState({});
    const [filteredInspections, setFilteredInspections] = useState({});
    const [new_inspections, setNewInspections] = useState(false);
    const [audit_id, setAuditId] = useState("");
    const [requirements, setRequirements] = useState({});
    const [recommendations, setRecommendations] = useState([])
    const [inspection, setInspection] = useState({});
    const [isHovered, setIsHovered] = useState(false);
    const [refreshDatesRange, setRefreshDatesRange] = useState([]);

    const resetAll = () => {
        setAuditId("");
        setInspection({});
        setRequirements({});
        setRecommendations([]);
        setInspections({});
        setFilteredInspections({});
        setNewInspections(true);
    };

    useEffect(() => {
        setBackdropOpen(true);
        async function getInspections() {
            const response = await axiosClient.get(
                "inspections/get_new_inspections"
            );
            if (Object.keys(response.data).length > 0) {
                // console.log(response.data);
                setInspections(response.data);
                setFilteredInspections(response.data); // For filtering
            }
            setBackdropOpen(false);

            setNewInspections(false);
        }

        getInspections();
    }, [new_inspections === true]);

    useEffect(() => {
        setBackdropOpen(true);
        if (audit_id) {
            // Get inspection data
            async function getInspection() {
                const response = await axiosClient.get(
                    `/inspections/get_portal_inspection/${audit_id}`
                );
                if (Object.keys(response.data).length > 0) {
                    // console.log("INSPECTION DATA: ", response.data);
                    setInspection(response.data);
                }
            }
            getInspection();

            //Get inspection requirements
            async function getRequirements() {
                const response = await axiosClient.get(
                    `/inspections/requirements/${audit_id}`
                );
                if (Object.keys(response.data).length > 0) {
                    // console.log("REQUIREMENTS: ", response.data);
                    setRequirements(response.data);
                }
            }
            getRequirements();

            //Get inspection recommendations
            async function getRecommendations() {
                const response = await axiosClient.get(
                    `/inspections/recommendations/${audit_id}`
                );
                if (response.data.length !== 0) {
                    // console.log("RECOMMENDATIONS: ", response.data);
                    setRecommendations(response.data);
                }
            }
            getRecommendations();
        }
        setBackdropOpen(false);
    }, [audit_id]);

    const handleRefreshdDateChange = (dates) => {
        setRefreshDatesRange(dates);
    };

    const refreshInspections = () => {
        setInspection({});
        setAuditId("");
        // console.log("Inspections: ", inspections);
        const startDate = dayjs(refreshDatesRange?.[0]).format("YYYY-MM-DD");
        const endDate = dayjs(refreshDatesRange?.[1]).format("YYYY-MM-DD");
        // console.log("Start Date: ", startDate);
        // console.log("End Date: ", endDate);
        setBackdropOpen(true);
        // Filter inspections to be between the selected dates (startDate and endDate)
        const filterInspections = Object.values(inspections).filter(
            (inspection) => {
                const surveyDate = dayjs(inspection.audit_survey_date).format(
                    "YYYY-MM-DD"
                );
                return surveyDate >= startDate && surveyDate <= endDate;
            }
        );
        // console.log("Filtering Inspections: ", filterInspections);
        setFilteredInspections(filterInspections);
        setBackdropOpen(false);
    };

    const listItemStyles = {
        paddingTop: 0,
        paddingBottom: 0,
        // For each ListItem, apply the following styles
        "& .MuiTypography-body1": {
            // For primary text in ListItemText
            fontSize: "14px",
            fontWeight: "bold",
        },
        "& .MuiListItemText-root": {
            marginLeft: "10px",
        },
    };

    return (
        <Box m="20px">
            <Header
                title="NEW SURVEYS"
                subtitle="Surveys that require confirmation and need to be sent to the broker"
            />

            <Card
                variant="outlined"
                sx={{
                    marginTop: "40px",
                    borderTop: 3,
                    borderTopColor: colors.greenAccent[400],
                    borderRadius: 0,
                }}
            >
                <CardContent>
                    <Box >
                        <RangePicker
                            allowClear={false}
                            value={
                                refreshDatesRange.length >
                                0
                                    ? refreshDatesRange
                                    : ""
                            }
                            onChange={
                                handleRefreshdDateChange
                            }
                            showTime={false}
                        />
                        <Button sx={{ padding: 0, margin: 0, minWidth: 'auto', textTransform: 'none' }}>
                            <RefreshIcon
                                sx={{ padding: 0, marginLeft: 1 }}
                                onClick={refreshInspections}
                            />
                        </Button>
                    </Box>
                    <Box sx = {{ marginBottom: 1 }}> <Typography variant="caption"> You can search for filtered surveys on this page by pressing CTR + F keys from the keyboard </Typography> </Box>
                    <Box display="flex" justifyContent="space-between">
                        {Object.keys(filteredInspections).length > 0 ? (
                            <>
                                {/* SURVEY SIDEBAR */}
                                <Box
                                    flex="1 1 30%"
                                    backgroundColor={colors.primary[400]}
                                    p="15px"
                                    borderRadius="4px"
                                >
                                    <List
                                        sx={{
                                            width: "100%",
                                            bgcolor: "background.paper",
                                            height: "600px", // Make the list scrollable
                                            overflow: "auto",
                                        }}
                                    >
                                        {/* Use map to loop through each inspection */}
                                        {filteredInspections.map((inspection) => (
                                            <>
                                                <ListItem
                                                    key={inspection.id}
                                                >
                                                    <ListItemButton
                                                        selected={inspection.audit_id == audit_id ? true : false}
                                                        onClick={() => {
                                                            setRequirements({});
                                                            setRecommendations([]);
                                                            setInspection({});
                                                            setAuditId(
                                                                inspection.audit_id
                                                            );
                                                        }}
                                                    >
                                                        <Box
                                                            display="flex"
                                                            justifyContent="space-between"
                                                        >
                                                            <Stack spacing={1}>
                                                                <Typography variant="h5">
                                                                    {
                                                                        inspection.audit_policy_holder
                                                                    }
                                                                </Typography>

                                                                <Typography
                                                                    variant="subtitle1"
                                                                    sx={{
                                                                        color: colors
                                                                            .grey[600],
                                                                    }}
                                                                >
                                                                    Survey
                                                                    Date:{" "}
                                                                    {inspection.audit_survey_date
                                                                        ? prettyDate(
                                                                              inspection.audit_survey_date
                                                                          )
                                                                        : ""}
                                                                </Typography>
                                                            </Stack>
                                                        </Box>
                                                    </ListItemButton>
                                                </ListItem>
                                                <Divider />
                                            </>
                                        ))}
                                    </List>
                                </Box>
                                {/* SURVEY DETAILS */}
                                <NewSurvey
                                    inspection={inspection}
                                    requirements={requirements}
                                    recommendations={recommendations}
                                    resetAll={resetAll}
                                />
                            </>
                        ) : (
                            <Typography variant="h4">
                                No new completed surveys are available, please
                                check later.
                            </Typography>
                        )}
                    </Box>
                </CardContent>
            </Card>
            {/* Render Backdrop */}
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
};

export default LoadSurvey;
