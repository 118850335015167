import {
    Box,
    Button,
    Select,
    TextField,
    useTheme,
    Tab,
    Tabs,
    Divider,
} from "@mui/material";
import { tokens } from "../../theme";
import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import MenuItem from "@mui/material/MenuItem";
import axiosClient from "../../axios";
import { useState, useEffect, useContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import UserContext from "../../contexts/UserContext";

const UserForm = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const { user } = useContext(UserContext);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [insurances, setInsurances] = useState([]);

    const closeSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarOpen(false);
    };

    useEffect(() => {
        async function getInsurances() {
            const response = await axiosClient.get("/insurances");
            // console.log("INSURANCES: ", response.data);
            // If logged in insurer_id is not equal to 0, filter Inserers where insurer_id is equal to logged in insurer_id
            let insurersList = response.data;
            if (user.insurer_id != 0) {
                insurersList = insurersList.filter(
                    (insurer) =>
                        insurer.id == user.insurer_id
                );
            }
            setInsurances(insurersList);
        }
        getInsurances();
    }, []);

    const handleTabChange = (e, tabIndex) => {
        setCurrentTabIndex(tabIndex);
        // console.log(currentTabIndex);
    };

    const handleFormSubmit = (values, { resetForm }) => {
        // console.log(values);
        async function registerUser() {
            const response = await axiosClient
                .post("user/register", {
                    email: values.email,
                    name: values.firstName + " " + values.lastName,
                    role: values.role,
                    password: values.password,
                    password_confirmation: values.password_confirmation,
                    insurer_id: parseInt(values.insurer_id),
                })
                .then((response) => {
                    // console.log("RESPONSE: ", response);
                    if (response.status === 201) {
                        // console.log("User registered successfully!");
                        // Open SnackBar
                        setSnackBarOpen(true);
                    } else {
                        console.log("Error while registering user!");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        registerUser();

        // Reset formit form
        resetForm({values: ''});
    };

    return (
        <Box m="20px">
            <Header
                title="CREATE USER"
                subtitle="Create a New System User Profile"
            />
            <Card
                variant="outlined"
                sx={{
                    marginTop: "40px",
                    borderTop: 3,
                    borderTopColor: colors.greenAccent[400],
                    borderRadius: 0,
                }}
            >
                <CardHeader
                    action={
                        <>
                            <Tabs
                                value={currentTabIndex}
                                onChange={handleTabChange}
                                indicatorColor="secondary"
                                textColor="secondary"
                            >
                                <Tab label="Occusurv Users" />
                                <Tab label="Insurers" />
                            </Tabs>
                        </>
                    }
                />
                <Divider />
                {/* ---------------------------Occusurv Users Tab----------------------------------- */}
                {(currentTabIndex === 0 && user.insurer_id == 0) && (
                    <CardContent>
                        <Formik
                            onSubmit={handleFormSubmit}
                            initialValues={occusurvInitialValues}
                            validationSchema={checkoutSchema}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box
                                        display="grid"
                                        gap="30px"
                                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                        sx={{
                                            "& > div": {
                                                gridColumn: isNonMobile
                                                    ? undefined
                                                    : "span 4",
                                            },
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="First Name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.firstName}
                                            name="firstName"
                                            error={
                                                !!touched.firstName &&
                                                !!errors.firstName
                                            }
                                            helpertext={
                                                touched.firstName &&
                                                errors.firstName
                                            }
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Last Name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.lastName}
                                            name="lastName"
                                            error={
                                                !!touched.lastName &&
                                                !!errors.lastName
                                            }
                                            helperText={
                                                touched.lastName &&
                                                errors.lastName
                                            }
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                            name="email"
                                            error={
                                                !!touched.email &&
                                                !!errors.email
                                            }
                                            helperText={
                                                touched.email && errors.email
                                            }
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <Select
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Role"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.role}
                                            name="role"
                                            error={
                                                !!touched.role && !!errors.role
                                            }
                                            helperText={
                                                touched.role && errors.role
                                            }
                                            sx={{ gridColumn: "span 2" }}
                                        >
                                            <MenuItem value={0}>Occusurv User</MenuItem>
                                        </Select>
                                    </Box>

                                    <Box
                                        display="flex"
                                        justifyContent="end"
                                        mt="20px"
                                    >
                                        <Button
                                            type="submit"
                                            color="secondary"
                                            variant="contained"
                                            // disabled={!Formik.dirty}
                                        >
                                            Create New User
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </CardContent>
                )}
                {/* ---------------------------Insurers Tab----------------------------------- */}
                {currentTabIndex === 1 && (
                    <CardContent>
                        <Formik
                            onSubmit={handleFormSubmit}
                            initialValues={insurerInitialValues}
                            validationSchema={checkoutSchema}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box
                                        display="grid"
                                        gap="30px"
                                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                        sx={{
                                            "& > div": {
                                                gridColumn: isNonMobile
                                                    ? undefined
                                                    : "span 4",
                                            },
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="First Name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.firstName}
                                            name="firstName"
                                            error={
                                                !!touched.firstName &&
                                                !!errors.firstName
                                            }
                                            helpertext={
                                                touched.firstName &&
                                                errors.firstName
                                            }
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Last Name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.lastName}
                                            name="lastName"
                                            error={
                                                !!touched.lastName &&
                                                !!errors.lastName
                                            }
                                            helperText={
                                                touched.lastName &&
                                                errors.lastName
                                            }
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                            name="email"
                                            error={
                                                !!touched.email &&
                                                !!errors.email
                                            }
                                            helperText={
                                                touched.email && errors.email
                                            }
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                        <Select
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Insurer"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.insurer_id}
                                            name="insurer_id"
                                            error={
                                                !!touched.insurer_id &&
                                                !!errors.insurer_id
                                            }
                                            helperText={
                                                touched.insurer_id &&
                                                errors.insurer_id
                                            }
                                            sx={{ gridColumn: "span 2" }}
                                        >
                                            {insurances.map(
                                                (insurance, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={insurance.id}
                                                    >
                                                        {insurance.insurer_name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                        <Select
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Role"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.role}
                                            name="role"
                                            error={
                                                !!touched.role && !!errors.role
                                            }
                                            helperText={
                                                touched.role && errors.role
                                            }
                                            sx={{ gridColumn: "span 1" }}
                                        >
                                            <MenuItem value={2}>Underwriter User</MenuItem>
                                            <MenuItem value={1}>Excecutive User</MenuItem>
                                        </Select>
                                    </Box>

                                    <Box
                                        display="flex"
                                        justifyContent="end"
                                        mt="20px"
                                    >
                                        <Button
                                            type="submit"
                                            color="secondary"
                                            variant="contained"
                                        >
                                            Create New User
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </CardContent>
                )}
            </Card>
            <Snackbar
                open={snackBarOpen}
                autoHideDuration={6000}
                onClose={closeSnackbar}
            >
                <Alert
                    onClose={closeSnackbar}
                    severity="success"
                    color="success"
                    sx={{ width: "100%" }}
                >
                    User profile successfully created!
                </Alert>
            </Snackbar>
        </Box>
    );
};

const checkoutSchema = yup.object().shape({
    firstName: yup.string().required("required"),
    lastName: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
    role: yup.string().required("required"),
});
const occusurvInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    password: "12345",
    password_confirmation: "12345",
    insurer_id: 0,
};

const insurerInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    role: 2,
    password: "12345",
    password_confirmation: "12345",
    insurer_id: "",
};

export default UserForm;
