import {
    Box,
    Button,
    Typography,
    useTheme,
    Grid,
    Tab,
    Tabs,
    Divider,
    Stack,
    Paper,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axiosClient from "../../axios";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SingleSelect from "../../components/SingleSelect";
import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import BarChart from "../../components/fireloss/BarChart";
import ScatterPlot from "../../components/fireloss/ScatterPlot";
import Sunburst from "../../components/fireloss/Sunburst";
import LineChart from "../../components/fireloss/LineChart";
import HeatMap from "../../components/fireloss/HeatMap";
import PieChart from "../../components/PieChart";
import DownloadIcon from "@mui/icons-material/Download";
import { set } from "lodash";

export default function FireLossReports() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [backdropOpen, setBackdropOpen] = useState(false);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [fireLossData, setFireLossData] = useState({});
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState("2012");
    const [filteredHeader, setFilteredHeader] = useState("SMOKING");
    const [yearCauseRows, setYearCauseRows] = useState([]);
    const [yearRows, setYearRows] = useState([]);
    const [pieCauseData, setPieCauseData] = useState([]);
    const [sunburstData, setSunburstData] = useState({});
    const [trendLines, setTrendLines] = useState([]);
    const [stackedBarData, setStackedBarData] = useState([{}]);
    const [totalHeatmapData, setTotalHeatmapData] = useState([{}]);
    const [damageHeatmapData, setDamageHeatmapData] = useState([{}]);

    const lossCauses = [
        { label: "SMOKING", value: "SMOKING" },
        { label: "ELECTRICAL", value: "ELECTRICAL" },
        { label: "OPEN FLAMES", value: "OPEN FLAMES" },
        { label: "COOKING", value: "COOKING" },
        { label: "HEATING", value: "HEATING" },
        { label: "WELDING", value: "WELDING" },
        { label: "LIGHTNING", value: "LIGHTNING" },
        { label: "ARSON", value: "ARSON" },
        { label: "UNREST", value: "UNREST" },
        { label: "UNDETERMINED", value: "UNDETERMINED" },
        { label: "OTHER", value: "OTHER" },
    ];

    const fire_loss_file_url =
        "https://portal.occusurv.co.za/api/public/fire_loss/Fire_stats.xlsx";

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        ...theme.typography.h6,
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
        // borderRadius: "2px",
        // boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        borderRadius: "1px",
        boxShadow: "0 1px 2px rgba(0, 0, 0, .1)",
    }));

    const handleTabChange = (e, tabIndex) => {
        setCurrentTabIndex(tabIndex);
    };

    // Fetch raw data
    useEffect(() => {
        setBackdropOpen(true);
        async function getFireLossUrl() {
            const response = await axiosClient.get("/fire_loss/load_data");
            if (response.data) {
                // console.log("FireLoss Data: ", response.data);
                setFireLossData(response.data);
                //  console.log(Object.keys(response.data));
                let yrs = [];
                Object.keys(response.data).forEach((key) => {
                    yrs.push({ label: key, value: key });
                });
                // console.log("Years: ", yrs);
                setYears(yrs);

                setBackdropOpen(false);
            }
        }
        getFireLossUrl();
    }, []);
    /****************************************** PREPARE ALL YEARS DATA ***************************************************/
    // Prepare data for trendlines
    useEffect(() => {
        const trendLineData = [];
        lossCauses?.map((cause) => {
            const trendlineObject = {};
            trendlineObject.id = cause.value;
            trendlineObject.color = "hsl(66, 70%, 50%)";
            trendlineObject.data = [];
            years?.map((year) => {
                const yearObject = {};
                yearObject.x = year.value;
                yearObject.y =
                    fireLossData[year?.value][
                        fireLossData[year?.value]?.length - 2
                    ][ // Get the second last row
                        lossCauses.findIndex(
                            (loss) => loss.value === cause.value
                        ) + 1
                    ];
                trendlineObject.data.push(yearObject);
            });
            trendLineData.push(trendlineObject);
        });
        setTrendLines(trendLineData);
        // console.log("Trendlines: ", trendLineData);
    }, [fireLossData]);

    // Prepare data for stacked bar chart
    useEffect(() => {
        const stackedBarData = [];
        years?.map((year) => {
            const yearObject = {};
            yearObject.year = year.value;
            yearObject.color = "hsl(66, 70%, 50%)";
            lossCauses?.map((cause) => {
                yearObject[cause.value] = Number(
                    fireLossData[year?.value][
                        fireLossData[year?.value]?.length - 2
                    ][
                        lossCauses.findIndex(
                            (loss) => loss.value === cause.value
                        ) + 1
                    ]
                );
            });
            stackedBarData.push(yearObject);
        });
        setStackedBarData(stackedBarData);
        // console.log("StackedBarData: ", stackedBarData);
    }, [fireLossData]);

    // Prepare data for heatmap
    useEffect(() => {
        const totalHeatmapData = [];
        const damageHeatmapData = [];
        years?.map((year) => {
            // From fireLossData[year?.value] create a copy of it and remove the first and last two rows
            const yearData = fireLossData[year?.value].slice(1, -2);
            // console.log("YearData: ", yearData);
            const yearTotalObject = {};
            const yearDamageObject = {};
            yearTotalObject.id = year.value;
            yearDamageObject.id = year.value;
            // Create yearObject.data as an array of objects of the form {x: "yearData['row']['first_index]", y: "yearData['row']['last_index]"}
            yearTotalObject.data = yearData.map((row) => {
                const rowObject = {};
                rowObject.x = row[0];
                rowObject.y = Number(row[row.length - 2]);
                return rowObject;
            });
            yearDamageObject.data = yearData.map((row) => {
                const rowObject = {};
                rowObject.x = row[0];
                const damage =
                    row[row.length - 1] === "-"
                        ? 0
                        : Number(row[row.length - 1]?.replace(/R|,/g, ""));
                rowObject.y = damage;
                return rowObject;
            });
            totalHeatmapData.push(yearTotalObject);
            damageHeatmapData.push(yearDamageObject);
        });
        setTotalHeatmapData(totalHeatmapData);
        setDamageHeatmapData(damageHeatmapData);
        // console.log("TotalHeatmapData: ", totalHeatmapData);
        // console.log("DamageHeatmapData: ", damageHeatmapData);
    }, [fireLossData]);

    /****************************************** PREPARE YEARLY DATA ******************************************************/
    // Prepare rows and columns for DataGrid
    const tableHeaders = fireLossData[selectedYear]?.[0];
    // console.log("Table headers: ", tableHeaders);
    let rows = [];
    if (Array.isArray(fireLossData[selectedYear])) {
        rows = fireLossData[selectedYear].slice(1).map((row, index) => {
            const rowObject = {};
            row.forEach((cell, cellIndex) => {
                let value = cell;
                // Check if the value is a string and starts with a zero
                if (typeof value === "string" && value.startsWith("0")) {
                    // Remove leading zeros, but keep a single '0' intact
                    value = value.replace(/^0+(.+)/, "$1");
                }
                // Convert value to number or float if it is a numeric string
                if (!isNaN(value)) {
                    value = parseFloat(value);
                }
                rowObject[tableHeaders[cellIndex]] = value;
            });
            rowObject.id = index; // DataGrid requires each row to have an 'id' property
            return rowObject;
        });
    }
    // console.log("Rows: ", rows);
    let columns = [];
    if (Array.isArray(tableHeaders)) {
        columns = tableHeaders.map((header) => ({
            field: header,
            headerName: header,
            width: header === "DESCRIPTION" ? 200 : 95,
        }));
    }

    // Filter columns and rows that will be used by charts
    useEffect(() => {
        const yearCauseRows = rows
            ?.map((row) => {
                const newRow = { ...row };
                delete newRow["RAND DAMAGE"];
                delete newRow["Total"];
                return newRow;
            })
            .slice(0, -2); // Remove last two rows

        setYearCauseRows(yearCauseRows);
        // console.log("Columns: ", filteredHeader);
        // console.log("YearsCauseRows: ", yearCauseRows);
        const yearRows = rows
            ?.slice(0, -2) // Remove last two rows first
            .map((row) => {
                // Remove 'R' and ',' from the string, then convert it to a number
                const damage =
                    row["RAND DAMAGE"] === "-"
                        ? 0
                        : Number(row["RAND DAMAGE"]?.replace(/R|,/g, ""));
                const newRow = {
                    DESCRIPTION:
                        row["DESCRIPTION"] === NaN ? 0 : row["DESCRIPTION"],
                    Total: row["Total"],
                    "Rand Damage": damage == NaN ? 0 : damage,
                };
                return newRow;
            });

        setYearRows(yearRows);
        // console.log("YearsRows: ", yearRows);
    }, [selectedYear, fireLossData]);

    // Prepare Sunburst data
    useEffect(() => {
        const sunburstData = {
            name: "nivo",
            color: "hsl(15, 70%, 50%)",
            children: lossCauses?.map((cause) => {
                const causeObject = {};
                causeObject.name = cause.value;
                causeObject.color = "hsl(66, 70%, 50%)";
                causeObject.children = [];
                yearCauseRows?.forEach((row) => {
                    const newRow = {};
                    newRow.name = row.DESCRIPTION;
                    newRow.value = row[cause.value];
                    newRow.color = "hsl(293, 70%, 50%)";
                    causeObject.children.push(newRow);
                });
                return causeObject;
            }),
        };
        setSunburstData(sunburstData);
        // console.log("Sunburst data: ", sunburstData);
    }, [yearCauseRows, selectedYear]);

    // Prepare data for PieChart
    useEffect(() => {
        if (Object.keys(fireLossData).length > 0) {
            // console.log("YearDataTotal: ", fireLossData[selectedYear][40]);
            // Loop through lossCauses array using forEach and console row.value
            const pieData = lossCauses?.map((row, index) => {
                const newRow = {};
                newRow.id = row.value;
                newRow.label = row.value;
                // Add 1 to the index because the values start at index 1 in the fireLossData array
                newRow.value = Number(
                    fireLossData[selectedYear][
                        fireLossData[selectedYear]?.length - 2
                    ][index + 1] // Get the second last row
                );
                return newRow;
            });
            // console.log("Pie data: ", pieData);
            setPieCauseData(pieData);
        }
    }, [selectedYear, fireLossData]);

    return (
        <Box m="20px">
            <Header
                title="FIRE LOSS"
                subtitle="Statistical reports on fire loss incidents - Data sourced from Fire Protection Association of Southern Africa"
            />

            <Card
                variant="outlined"
                sx={{
                    marginTop: "40px",
                    borderTop: 3,
                    borderTopColor: colors.greenAccent[400],
                    borderRadius: 0,
                }}
            >
                <CardHeader
                    action={
                        <>
                            <Tabs
                                value={currentTabIndex}
                                onChange={handleTabChange}
                                indicatorColor="secondary"
                                textColor="secondary"
                            >
                                <Tab label="All Years" />
                                <Tab label="Yearly" />
                            </Tabs>
                        </>
                    }
                />
                <Divider />
                {/* --------------------------- Tab 0: All time ----------------------------------- */}
                {currentTabIndex === 0 && (
                    <CardContent sx={{ minHeight: "50vh" }}>
                        <Stack spacing={2}>
                            {/* **************************** STACK 1: Trend Lines ********************************** */}
                            <Item>
                                <Typography
                                    variant="h5"
                                    textAlign={"start"}
                                    sx={{ fontWeight: "bold" }}
                                >
                                    Trend lines of total fire loss causes of
                                    industries across the years
                                </Typography>
                                <Box height="75vh">
                                    <LineChart data={trendLines} />
                                </Box>
                            </Item>
                            {/* **************************** STACK 2: Stacked Barchart ********************************** */}
                            <Item>
                                <Typography
                                    variant="h5"
                                    textAlign={"start"}
                                    sx={{ fontWeight: "bold" }}
                                >
                                    Total fire loss causes of industries per
                                    year
                                </Typography>
                                <Box height="75vh">
                                    <BarChart
                                        data={stackedBarData}
                                        keys={lossCauses.map(
                                            (cause) => cause.value
                                        )}
                                        indexBy={"year"}
                                        isStacked={true}
                                    />
                                </Box>
                            </Item>
                            {/* **************************** STACK 3: HeatMaps ********************************** */}
                            <Item>
                                <Grid container spacing={2} marginBottom={2}>
                                    <Grid item md={12}>
                                        <Typography
                                            variant="h5"
                                            textAlign={"start"}
                                            sx={{ fontWeight: "bold" }}
                                        >
                                            Rand(R) Damage distribution across
                                            different industries
                                        </Typography>
                                        <Box height="75vh">
                                            <HeatMap
                                                data={damageHeatmapData}
                                                scale={{
                                                    min: 0,
                                                    max: 100000000,
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item md={12}>
                                        <Typography
                                            variant="h5"
                                            textAlign={"start"}
                                            sx={{ fontWeight: "bold" }}
                                        >
                                            Total distribution of causes of fire
                                            loss across different industries
                                        </Typography>
                                        <Box height="75vh">
                                            <HeatMap
                                                data={totalHeatmapData}
                                                scale={{ min: 0, max: 10000 }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Stack>
                    </CardContent>
                )}

                {/* --------------------------- Tab 1: Yearly ----------------------------------- */}
                {currentTabIndex === 1 && (
                    <CardContent sx={{ minHeight: "50vh" }}>
                        <Stack spacing={2}>
                            {/* **************************** STACK 1: Filters ********************************** */}
                            <Item>
                                <Grid container spacing={2} marginBottom={2}>
                                    <Grid item md={3}>
                                        <SingleSelect
                                            options={years}
                                            // Find the array index of selectedYear in years array and set it as selectedValueIndex
                                            selectedValueIndex={years.findIndex(
                                                (year) =>
                                                    year.value === selectedYear
                                            )}
                                            onSelectedValue={setSelectedYear}
                                            label={"Select year:"}
                                        />
                                    </Grid>
                                    <Grid item md={3}>
                                        <SingleSelect
                                            options={lossCauses}
                                            // Find the array index of filteredHeader in lossCauses array and set it as selectedValueIndex
                                            selectedValueIndex={lossCauses.findIndex(
                                                (loss) =>
                                                    loss.value ===
                                                    filteredHeader
                                            )}
                                            onSelectedValue={setFilteredHeader}
                                            label={"Select cause of loss:"}
                                        />
                                    </Grid>
                                </Grid>
                            </Item>
                            {/* **************************** STACK 2: BarChar per year per cause of loss ********************************** */}
                            <Item>
                                <Typography
                                    variant="h5"
                                    textAlign={"start"}
                                    sx={{ fontWeight: "bold" }}
                                >
                                    {filteredHeader} fire loss in {selectedYear}
                                </Typography>
                                <Box height="75vh">
                                    <BarChart
                                        data={yearCauseRows}
                                        keys={[filteredHeader]}
                                        indexBy={"DESCRIPTION"}
                                        isStacked={false}
                                    />
                                </Box>
                            </Item>
                            {/* **************************** STACK 3: Piechart, Sunburst and ScatterPlott ********************************** */}
                            <Item>
                                <Grid container spacing={2} marginBottom={2}>
                                    {/* ********************** PieChart ********************/}
                                    <Grid item md={6}>
                                        <Typography
                                            variant="h5"
                                            textAlign={"start"}
                                            sx={{ fontWeight: "bold" }}
                                        >
                                            Percentage of total cause of fires
                                            in {selectedYear}
                                        </Typography>
                                        <Box height="50vh" width="100%">
                                            <PieChart data={pieCauseData} />
                                        </Box>
                                    </Grid>
                                    {/* ********************** Sunburst ********************/}
                                    <Grid item md={6}>
                                        <Typography
                                            variant="h5"
                                            textAlign={"start"}
                                            sx={{ fontWeight: "bold" }}
                                        >
                                            Relationships between cause of fire
                                            and industries in {selectedYear}
                                        </Typography>
                                        <Box height="50vh" width="100%">
                                            <Sunburst data={sunburstData} />
                                        </Box>
                                    </Grid>
                                    {/* ********************** Barchart ********************/}
                                    <Grid item md={12}>
                                        <Typography
                                            variant="h5"
                                            textAlign={"start"}
                                            sx={{ fontWeight: "bold" }}
                                        >
                                            Total fire loss per Industry vs Rand
                                            damage in {selectedYear}
                                        </Typography>
                                        <Box height="75vh" width="100%">
                                            <ScatterPlot data={yearRows} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Item>
                            {/* **************************** STACK 4: DataGrid Table ********************************** */}
                            <Item>
                                <Typography
                                    variant="h5"
                                    textAlign={"start"}
                                    sx={{ fontWeight: "bold" }}
                                >
                                    Fire loss statistics in {selectedYear}
                                </Typography>
                                <Box textAlign={"start"} marginBottom={3}>
                                    <a
                                        href={fire_loss_file_url}
                                        download
                                        style={{ textDecoration: "none" }}
                                    >
                                        <Button
                                            variant="link"
                                            // color="inherit"
                                            size="small"
                                            style={{
                                                color: colors.greenAccent[300],
                                            }}
                                            startIcon={<DownloadIcon />}
                                        >
                                            {"Exel file"}
                                        </Button>
                                    </a>
                                </Box>
                                <Box width={"75vw"}>
                                    <DataGrid
                                        rows={rows}
                                        columns={columns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    page: 0,
                                                    pageSize: 10,
                                                },
                                            },
                                        }}
                                        pageSizeOptions={[10, 20, 50]}
                                    />
                                </Box>
                            </Item>
                        </Stack>
                    </CardContent>
                )}
            </Card>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}
