import { useState, useEffect, useContext } from "react";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import DashboardCalendar from "./scenes/dashboard/Calendar";
import SurveyDashboard from "./scenes/dashboard/SurveyDashboard";
import Requirements from "./scenes/requirements";
import CreateUser from "./scenes/user/createUser";
import ListUsers from "./scenes/user/listUsers";
import Insurances from "./scenes/user/Insurances";
import Templates from "./scenes/templates/Templates";
import InspectionsReport from "./scenes/inspections/InspectionsReport";
import AllInspections from "./scenes/requirements/AllInspections";
import NextInspection from "./scenes/inspections/NextInspection";
import { Routes, Route, useLocation } from "react-router-dom";
import Login from "./components/Login";
import { UserContextProvider } from "./contexts/UserContext";
import { Calendar } from "@fullcalendar/core";
import LoadSurveys from "./scenes/inspections/LoadSurveys";
import ArchivedSurveys from "./scenes/requirements/ArchivedSurveys";
import AuditTrail from "./scenes/audittrail/AuditTrail";
import FireLossReports from "./scenes/fireloss/FireLossReports";
import FireLossUpload from "./scenes/fireloss/FireLossUpload";
import InvoiceableSurveys from "./scenes/inspections/InvoiceableSurveys";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  // Get the current Route path and set isLogin to true if it is "/login"
  const location = useLocation();
  const [isLogin, setIsLogin] = useState(null);
  // const { isLogin } = useContext(UserContext);

  const getIsLoginFlag = (flag) => {
    setIsLogin(flag);
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <UserContextProvider>
          <CssBaseline />
          <div className="app">
            {/* If the Login form requested show it, else show other components */}
            {isLogin ?
              <Routes>
                <Route path="/login" element={<Login getIsLoginFlag={getIsLoginFlag} />} />
              </Routes>
              : (
                <>
                  <Sidebar isSidebar={isSidebar} getIsLoginFlag={getIsLoginFlag} /><main className="content">
                    <Topbar setIsSidebar={setIsSidebar} getIsLoginFlag={getIsLoginFlag} />
                    <Routes>
                      <Route path="/" element={<DashboardCalendar />} />
                      <Route path="/calendar" element={<DashboardCalendar />} />
                      <Route path="/dashboard" element={<SurveyDashboard />} />
                      <Route path="/fire_loss_reports" element={<FireLossReports />} />
                      <Route path="/list_users" element={<ListUsers />} />
                      <Route path="/add_user" element={<CreateUser />} />
                      <Route path="/audit_trail" element={<AuditTrail />} />
                      <Route path="/templates" element={<Templates />} />
                      <Route path="/load_surveys" element={<LoadSurveys />} />
                      <Route path="/archived_surveys" element={<ArchivedSurveys />} />
                      <Route path="/all_inspections" element={<AllInspections />} />
                      <Route path="/invoiceable_surveys" element={<InvoiceableSurveys />} />
                      <Route path="/inspections_report" element={<InspectionsReport />} />
                      <Route path="/sync_inspection" element={<NextInspection />} />
                      <Route path="/requirements" element={<Requirements />} />
                      <Route path="/insurances" element={<Insurances />} />
                      <Route path="/fire_loss_upload" element={<FireLossUpload />} />
                      <Route path="*" element={<div> Page Not Found or You do not have permission.</div>}/>
                    </Routes>
                  </main>
                </>
              )}
          </div>
        </UserContextProvider>
      </ThemeProvider>
    </ColorModeContext.Provider >

  );
}

export default App;
