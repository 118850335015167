const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];
const getPrettyDate = (date) => {
    date = date.split("T")[0];
    const newDate = date.split("-");
    const month = months[newDate[1] - 1];
    return `${month} ${newDate[2]}, ${newDate[0]}`;
};

export default getPrettyDate;