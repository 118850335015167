import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { ResponsiveSunburst } from "@nivo/sunburst";

const Sunburst = ({ data }) => {
    return (
        <ResponsiveSunburst
            data={data}
            margin={{ top: 40, right: 20, bottom: 20, left: 20 }}
            identity="nivo"
            id="name"
            value="value"
            cornerRadius={2}
            borderWidth={1}
            borderColor="white"
            colors={{ scheme: "category10" }}
            childColor={{
                from: "color",
                modifiers: [["brighter", 0.1]],
            }}
            enableArcLabels={true}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 1.4]],
            }}
            // tooltip={({ id, value }) => (
            //     <strong>
            //         {id}: {value}
            //     </strong>
            // )}
        />
    );
};

export default Sunburst;
