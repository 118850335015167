import React from "react";
import { Typography } from "@mui/material";
import Select from "react-dropdown-select";

export default function MultiHorizontalSelect({
    options,
    // selectedValueIndex,
    onSelectedValues,
    label,
}) {
    return (
        <>
            <Select
                multi
                options={options}
                // Use selectedValueIndex as the index of the selected value in options array
                // values={[options[selectedValueIndex]]}
                onChange={(value) => {
                    // console.log("MultiValues: ", value);
                    onSelectedValues(value);
                }}
                values={[]}
                // onChange={(value) => console.log("MultiValues: ", value)}
            />
        </>
    );
}
