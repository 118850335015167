import {
    Box,
    Button,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import axiosClient from "../../axios";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

const NewTemplate = ({ setIsNewTemplate }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(true);
    const [isError, setIsError] = useState(false);

    const closeSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarOpen(false);
    };

    const handleFormSubmit = (values) => {
        console.log(values);
        async function createTemplate() {
            const response = await axiosClient
                .post("/templates", {
                    template_id: values.template_id,
                    template_name: values.template_name,
                    template_created_at: values.template_created_at,
                    template_modified_at: values.template_modified_at,
                })
                .then((response) => {
                    // console.log("RESPONSE: ", response);
                    if (response.status === 201) {
                        // Open SnackBar
                        setSnackBarOpen(true);
                        // Close create new template form
                        setIsNewTemplate(false);
                        console.log("Template created successfully!");
                    } else {
                        console.log("Error while creating a template!");
                    }
                })
                .catch((error) => {
                    setIsError(true);
                    console.log("ERROR: ", error.response.data.message);
                });
        }
        createTemplate();
    };

    const cancelCreateTemplate = () => {
        // Close create new template form
        setIsNewTemplate(false);
    };

    return (
        <Box m="20px">
            {isError && (
                <Collapse in={alertOpen}>
                    <Alert
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setAlertOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        <Typography variant="h6" fontWeight="bold">
                            Duplicate error — This template ID already exists in
                            the database!
                        </Typography>
                    </Alert>
                </Collapse>
            )}
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": {
                                    gridColumn: isNonMobile
                                        ? undefined
                                        : "span 4",
                                },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Template ID"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.template_id}
                                name="template_id"
                                error={
                                    !!touched.template_id &&
                                    !!errors.template_id
                                }
                                helpertext={
                                    touched.template_id && errors.template_id
                                }
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Template Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.template_name}
                                name="template_name"
                                error={
                                    !!touched.template_name &&
                                    !!errors.template_name
                                }
                                helperText={
                                    touched.template_name &&
                                    errors.template_name
                                }
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Created Date"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.template_created_at}
                                name="template_created_at"
                                error={
                                    !!touched.template_created_at &&
                                    !!errors.template_created_at
                                }
                                helperText={
                                    touched.template_created_at &&
                                    errors.template_created_at
                                }
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Modified Date"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.template_modified_at}
                                name="template_modified_at"
                                error={
                                    !!touched.template_modified_at &&
                                    !!errors.template_modified_at
                                }
                                helperText={
                                    touched.template_modified_at &&
                                    errors.template_modified_at
                                }
                                sx={{ gridColumn: "span 2" }}
                            />
                        </Box>

                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button
                                sx={{
                                    marginRight: "10px",
                                    bgcolor: colors.redAccent[400],
                                }}
                                variant="contained"
                                onClick={cancelCreateTemplate}
                                startIcon={<CancelIcon />}
                            >
                                Cancel
                            </Button>

                            <Button
                                type="submit"
                                color="secondary"
                                variant="contained"
                                startIcon={<AddIcon />}
                            >
                                Create Template
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>

            <Snackbar
                open={snackBarOpen}
                autoHideDuration={6000}
                onClose={closeSnackbar}
            >
                <Alert
                    onClose={closeSnackbar}
                    severity="success"
                    color="success"
                    sx={{ width: "100%" }}
                >
                    Template created successfully!
                </Alert>
            </Snackbar>
        </Box>
    );
};

const checkoutSchema = yup.object().shape({
    template_id: yup.string().required("required"),
    template_name: yup.string().required("required"),
    template_created_at: yup.string().required("required"),
    template_modified_at: yup.string().required("required"),
});
const initialValues = {
    template_id: "",
    template_name: "",
    template_created_at: "",
    template_modified_at: "",
};

export default NewTemplate;
