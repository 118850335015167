import {
    Box,
    Button,
    Typography,
    useTheme,
    Grid,
    Divider,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axiosClient from "../../axios";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import GetAppIcon from "@mui/icons-material/GetApp";
import FireLossDropzone from "../../components/FireLossDropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export default function FireLossUpload() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const fire_loss_file_url =
        "https://portal.occusurv.co.za/api/public/fire_loss/Fire_stats.xlsx";
    // Extract the file name from the URL
    const fire_loss_filename = fire_loss_file_url.split("/").pop();

    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [files, setFiles] = useState([]);

    const closeSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarOpen(false);
    };

    const handleClickUpload = async () => {
        // Rename the file to Fire_stats.xlsx
        if (files.length > 0) {
            const file = files[0];
            const newFile = new File([file], "Fire_stats.xlsx", {
                type: file.type,
            });
            // console.log("New file", newFile);

            const formData = new FormData();
            formData.append("file", newFile);

            const response = await axiosClient
                .post("/fire_loss/store_excel_file", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    // console.log(response);
                    setSnackBarMessage(response.data.message);
                    setSnackBarOpen(true);
                })
                .catch((error) => {
                    console.log("ERROR: ", error);
                });
        }
    };

    return (
        <Box m="20px">
            <Header
                title="FIRE LOSS"
                subtitle="Upload the file loss statistics file here"
            />

            <Card
                variant="outlined"
                sx={{
                    marginTop: "40px",
                    borderTop: 3,
                    borderTopColor: colors.greenAccent[400],
                    borderRadius: 0,
                }}
            >
                <CardContent>
                    <Box height="75vh">
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Typography variant="h6">
                                    {" "}
                                    You can download the fire loss statistics
                                    file below
                                </Typography>
                                <Box
                                    sx={{
                                        mr: "10px",
                                        pt: "10px",
                                    }}
                                >
                                    <a
                                        href={fire_loss_file_url}
                                        download
                                        style={{ textDecoration: "none" }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="inherit"
                                            style={{
                                                color: colors.greenAccent[400],
                                            }}
                                            startIcon={
                                                <GetAppIcon
                                                    style={{ fontSize: 150 }}
                                                />
                                            }
                                        >
                                            {fire_loss_filename}
                                        </Button>
                                    </a>
                                </Box>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={7}>
                                <Typography variant="h6">
                                    {" "}
                                    Upload the new fire loss statistics file to
                                    replace the extisting one downloadable on
                                    the left
                                </Typography>
                                <Box
                                    sx={{
                                        mt: "10px",
                                    }}
                                >
                                    <FireLossDropzone setFiles={setFiles} />
                                    <Button
                                        sx={{ mt: "10px" }}
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        startIcon={<CloudUploadIcon />}
                                        onClick={handleClickUpload}
                                    >
                                        Upload file
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Snackbar
                        open={snackBarOpen}
                        autoHideDuration={6000}
                        onClose={closeSnackbar}
                    >
                        <Alert
                            onClose={closeSnackbar}
                            severity="success"
                            color="success"
                            sx={{ width: "100%" }}
                        >
                            Fire Loss Statistics file uploaded successfully!
                        </Alert>
                    </Snackbar>
                </CardContent>
            </Card>
            <Snackbar
                open={snackBarOpen}
                autoHideDuration={6000}
                onClose={closeSnackbar}
            >
                <Alert
                    onClose={closeSnackbar}
                    severity="success"
                    color="success"
                    sx={{ width: "100%" }}
                >
                    {snackBarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}
