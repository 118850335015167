import React, { Component } from "react";
import { DropzoneArea } from "mui-file-dropzone";

// https://github.com/Deckstar/mui-file-dropzone

class FileDropzone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
        };
    }
    handleChange(files) {
        this.setState({
            files: files,
        });
        // Pass the uploaded files to the parent component via the setFiles prop
        this.props.setFiles(files);
    }
    render() {
        return (
            <DropzoneArea
                onChange={this.handleChange.bind(this)}
                acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                filesLimit={10}
                maxFileSize={10000000}
                dropzoneText={"Drag and drop up to 10 image files not exceeding 10mb each here or click"}
            />
        );
    }
}

export default FileDropzone;
