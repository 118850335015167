import axios from "axios";
import { useNavigate } from "react-router-dom";

const axiosClient = axios.create({
  //   baseURL: `${import.meta.env.API_BASE_URL}/api`,
  // baseURL: `http://localhost:8000/api`,
  baseURL: `https://portal.occusurv.co.za/api/public/api`,
});

axiosClient.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('TOKEN')}`
  return config
});

axiosClient.interceptors.response.use(response => {
  return response;
}, async error => {
  if (error.response) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('TOKEN')
      // window.location.reload();
      // Navigate to login page
      // const router = useRouter();
      // router.navigate('/login')
      // const navigate = useNavigate();
      // navigate('/login', { replace: true });
      // window.location.href = '/login';
      return error;
    } /*else if (error.response.status === 429) {
      // Wait for 1 second before retrying the request
      await new Promise(resolve => setTimeout(resolve, 1000));
      return axiosClient(error.config);
    }*/
  }
  throw error;
})

export default axiosClient;