import { Box, Typography, useTheme, Tab, Tabs, Divider } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axiosClient from "../../axios";
import { useState, useEffect, useContext } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import UserContext from "../../contexts/UserContext";

const User = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user } = useContext(UserContext);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    // Get List of users through axios call
    const [occusurvUsers, setOccusurvUsers] = useState([]);
    const [insurerUsers, setInsurerUsers] = useState([]);
    // const [insurer, setInsurer] = useState([]);

    useEffect(() => {
        // Load Occursurv Users
        async function getOccusurvUsers() {
            const response = await axiosClient.get("user/occusurv");
            setOccusurvUsers(response.data);
            // console.log("Occusurv Users: ", response.data);
        }
        getOccusurvUsers();

        // Load Insurance users
        async function getInsurerUsers() {
            const response = await axiosClient.get("user/insurer");
            let insurerUsers = response.data;
            // console.log("Insurer Users: ", insurerUsers);
            // Loop through insurerUsers using key
            for (const key in insurerUsers) {
                // Get insurer_id
                // let insurer_id = insurerUsers[key].insurer_id;
                // Get insurer data by insurer_id
                async function getInsurer() {
                    const response = await axiosClient
                        .get(`insurer/${insurerUsers[key].insurer_id}`)
                        .then((response) => {
                            // console.log("Insurer DATA: ", response.data);
                            if (insurerUsers[key]) {
                                insurerUsers[key].insurer_name =    response.data.insurer_name;
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
                getInsurer();
            }
            // If logged in insurer_id is not equal to 0, filter insurerUsers where insurer_id is equal to logged in insurer_id
            if (user.insurer_id != 0) {
                insurerUsers = insurerUsers.filter(
                    (insurerUser) =>
                        insurerUser.insurer_id == user.insurer_id
                );
            }

            setInsurerUsers(insurerUsers);
            // console.log("Insurer Users: ", insurerUsers);
        }
        getInsurerUsers();
    }, []);
    // console.log("Insurance Users: ", insurerUsers);

    const columns = [
        // { field: "id", headerName: "ID" },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
        },
        {
            field: "role",
            headerName: "Access Level",
            flex: 1,
        },
        (currentTabIndex == 1 && user.role == 'Admin') && {
            field: "insurer_name",
            headerName: "Insurance Name",
            flex: 1,
        },
    ];

    const handleTabChange = (e, tabIndex) => {
        setCurrentTabIndex(tabIndex);
        // console.log(currentTabIndex);
    };

    return (
        <Box m="20px">
            <Header title="USERS" subtitle="Manage user accounts" />

            <Card
                variant="outlined"
                sx={{
                    marginTop: "40px",
                    borderTop: 3,
                    borderTopColor: colors.greenAccent[400],
                    borderRadius: 0,
                }}
            >
                <CardHeader
                    action={
                        <>
                            <Tabs
                                value={currentTabIndex}
                                onChange={handleTabChange}
                                indicatorColor="secondary"
                                textColor="secondary"
                            >
                                <Tab label="Occusurv Users"/>
                                <Tab label="Insurers" />
                            </Tabs>
                        </>
                    }
                />
                <Divider />
                <CardContent>
                    <Box
                        m="0 0 0 0"
                        height="75vh"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: colors.blueAccent[700],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: colors.blueAccent[700],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                        }}
                    >
                        {(currentTabIndex == 0 && user.insurer_id == 0) && (
                            <DataGrid rows={occusurvUsers} columns={columns} />
                        )}
                        {currentTabIndex == 1 && (
                            <DataGrid rows={insurerUsers} columns={columns} />
                        )}
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default User;
