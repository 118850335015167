import React, { useEffect, useState, useContext } from "react";
import {
    Box,
    Stack,
    Paper,
    useTheme,
    Grid,
    Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Header from "../../components/Header";
import MultiSelect from "../../components/MultiSelect";
import { tokens } from "../../theme";
import axiosClient from "../../axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import GoogleMaps from "./GoogleMaps";
import PieChart from "../../components/PieChart";
import UserContext from "../../contexts/UserContext";
import { set } from "lodash";

const SurveyDashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { user } = useContext(UserContext);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [backdropOpen, setBackdropOpen] = useState(true);
    const [data, setData] = useState([]);
    const [policyHolders, setPolicyHolders] = useState([]);
    const [brokers, setBrokers] = useState([]);
    const [totalFireClassesSum, setTotalFireClassesSum] = useState(0);
    const [locations, setLocations] = useState([]);
    const [fireInceptionData, setFireInceptionData] = useState([]);
    const [fireLoadData, setFireLoadData] = useState([]);
    const [fireSpreadData, setFireSpreadData] = useState([]);
    const [perilsFireData, setPerilsFireData] = useState([]);
    const [perilsExplosionData, setPerilsExplosionData] = useState([]);
    const [perilsFloodInlandData, setPerilsFloodInlandData] = useState([]);
    const [perilsFloodCoastalData, setPerilsFloodCoastalData] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({});

    const closeSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarOpen(false);
    };

    let zarFormatter = new Intl.NumberFormat("en-ZA", {
        style: "currency",
        currency: "ZAR",
        minimumFractionDigits: 0,
    });

    useEffect(() => {
        async function getData() {
            const response = await axiosClient
                .get("/google_sheets")
                .then((response) => {
                    // if status code is 204, return
                    if (response.status === 204) {
                        setBackdropOpen(false);
                        setSnackBarMessage("Admin users are not linked to any Google Sheet to display on the dashboard");
                        setSnackBarOpen(true);
                        return;
                    }
                    // console.log("Response: ", response.data);
                    setData(response.data);
                })
                .catch((error) => {
                    console.log("ERROR", error);
                });
        }
        getData();
    }, []);

    // When selectedFilters changes, filter the data
    useEffect(() => {
        // If there is no data, return
        if (data.length === 0) {
            return;
        }
        // console.log("selectedFilters: ", selectedFilters);
        setBackdropOpen(true);
        async function filterData() {
            // Filter the data
            let filteredData = data;
            // Filter by selected filters
            Object.keys(selectedFilters).forEach((filterKey) => {
                filteredData = filteredData.filter((item) =>
                    selectedFilters[filterKey].includes(item[filterKey])
                );
            });
            setData(filteredData);
        }
        // Call filterData() after data is set
        filterData().then(() => {
            setBackdropOpen(false);
        });
    }, [selectedFilters]);

    useEffect(() => {
        // If there is no data, return
        if (data.length === 0) {
            return;
        }
        setBackdropOpen(true);
        async function setFields() {
            // A list of brokers
            const brokers = [];
            data.forEach((item) => {
                if (
                    item["Broker:"] &&
                    !brokers.some((holder) => holder.id === item["Broker:"])
                ) {
                    brokers.push({
                        id: item["Broker:"],
                        label: item["Broker:"],
                    });
                }
            });
            setBrokers(brokers);

            // A list of policy holders
            const policyHolders = [];
            data.forEach((item) => {
                if (
                    item["Policyholder:"] &&
                    !policyHolders.some(
                        (holder) => holder.id === item["Policyholder:"]
                    )
                ) {
                    policyHolders.push({
                        id: item["Policyholder:"],
                        label: item["Policyholder:"],
                    });
                }
            });
            setPolicyHolders(policyHolders);

            // Compute the sum of Fire Classes Sum Insured
            let totalSumInsured = 0;
            data.forEach((item) => {
                if (item["Fire Classes Sum Insured:"]) {
                    // Remove the 'R' prefix and commas, then convert to a number
                    let sumInsured = Number(
                        item["Fire Classes Sum Insured:"]
                            .replace("R", "")
                            .replace(/,/g, "")
                    );
                    if (isNaN(sumInsured)) {
                        sumInsured = 0;
                    }
                    totalSumInsured += sumInsured;
                }
            });
            setTotalFireClassesSum(totalSumInsured);

            // Get locations
            const locs = [];
            data.forEach((item) => {
                if (item["Location:"]) {
                    locs.push({
                        policyholder: item["Policyholder:"],
                        fireClassesSum: item["Fire Classes Sum Insured:"],
                        location: item["Location:"],
                    });
                }
            });
            setLocations(locs);

            // Fire Statistics
            const fireStats = ["High", "Medium", "Low", "NULL"];
            const fireColors = [
                "hsl(80, 70%, 50%)",
                "hsl(122, 70%, 50%)",
                "hsl(346, 70%, 50%)",
                "hsl(276, 70%, 50%)",
            ];

            // Set Fire Inception Statistics
            const fireInceptionStats = data.reduce((acc, obj) => {
                const key = obj["Fire inception:"] || "NULL";
                acc[key] = (acc[key] || 0) + 1;
                return acc;
            }, {});

            const fireInceptionData = fireStats.reduce((acc, stat, index) => {
                if (fireInceptionStats[stat]) {
                    acc.push({
                        id: stat,
                        label: stat,
                        value: fireInceptionStats[stat],
                        color: fireColors[index],
                    });
                }
                return acc;
            }, []);
            setFireInceptionData(fireInceptionData);

            // Set Fire Load Statistics
            const fireLoadStats = data.reduce((acc, obj) => {
                const key = obj["Fire load:"] || "NULL";
                acc[key] = (acc[key] || 0) + 1;
                return acc;
            }, {});

            const fireLoadData = fireStats.reduce((acc, stat, index) => {
                if (fireLoadStats[stat]) {
                    acc.push({
                        id: stat,
                        label: stat,
                        value: fireLoadStats[stat],
                        color: fireColors[index],
                    });
                }
                return acc;
            }, []);
            setFireLoadData(fireLoadData);

            // Set Fire Spread Statistics
            const fireSpreadStats = data.reduce((acc, obj) => {
                const key = obj["Fire spread:"] || "NULL";
                acc[key] = (acc[key] || 0) + 1;
                return acc;
            }, {});

            const fireSpreadData = fireStats.reduce((acc, stat, index) => {
                if (fireSpreadStats[stat]) {
                    acc.push({
                        id: stat,
                        label: stat,
                        value: fireSpreadStats[stat],
                        color: fireColors[index],
                    });
                }
                return acc;
            }, []);
            setFireSpreadData(fireSpreadData);

            const perilsStats = [
                "Likely",
                "Possible",
                "Rare",
                "Unlikely",
                "Yes - High",
                "Yes - Low",
                "No",
                "Yes - Medium",
                "Very low",
                "Very high",
                "Medium",
                "Medium to high",
                "Low",
                "NULL",
            ];
            const perilsColors = [
                "hsl(80, 70%, 50%)",
                "hsl(122, 70%, 50%)",
                "hsl(346, 70%, 50%)",
                "hsl(291, 70%, 50%)",
                "hsl(346, 70%, 50%)",
                "hsl(276, 70%, 50%)",
            ];

            // Set Perils - Fire Statistics
            const perilsFireStats = data.reduce((acc, obj) => {
                const key = obj["Perils - Fire"] || "NULL";
                acc[key] = (acc[key] || 0) + 1;
                return acc;
            }, {});

            const perilsFireData = perilsStats.reduce((acc, stat, index) => {
                if (perilsFireStats[stat]) {
                    acc.push({
                        id: stat,
                        label: stat,
                        value: perilsFireStats[stat],
                        color: perilsColors[index],
                    });
                }
                return acc;
            }, []);
            setPerilsFireData(perilsFireData);

            // Set Perils - Explosion Statistics
            const perilsExplosionStats = data.reduce((acc, obj) => {
                const key = obj["Perils - Explosion"] || "NULL";
                acc[key] = (acc[key] || 0) + 1;
                return acc;
            }, {});

            const perilsExplosionData = perilsStats.reduce(
                (acc, stat, index) => {
                    if (perilsExplosionStats[stat]) {
                        acc.push({
                            id: stat,
                            label: stat,
                            value: perilsExplosionStats[stat],
                            color: perilsColors[index],
                        });
                    }
                    return acc;
                },
                []
            );
            setPerilsExplosionData(perilsExplosionData);
            // console.log("perilsExplosionData: ", perilsExplosionData);

            // Set Perils - Flood(inland) Statistics
            const perilsFloodInlandStats = data.reduce((acc, obj) => {
                const key = obj["Perils - Flood (inland)"] || "NULL";
                acc[key] = (acc[key] || 0) + 1;
                return acc;
            }, {});

            const perilsFloodInlandData = perilsStats.reduce(
                (acc, stat, index) => {
                    if (perilsFloodInlandStats[stat]) {
                        acc.push({
                            id: stat,
                            label: stat,
                            value: perilsFloodInlandStats[stat],
                            color: perilsColors[index],
                        });
                    }
                    return acc;
                },
                []
            );
            setPerilsFloodInlandData(perilsFloodInlandData);
            // console.log("perilsFloodInData: ", perilsFloodInlandData);

            // Set Perils - Flood(coastal) Statistics
            const perilsFloodCoastalStats = data.reduce((acc, obj) => {
                const key = obj["Perils - Flood (coastal)"] || "NULL";
                acc[key] = (acc[key] || 0) + 1;
                return acc;
            }, {});

            const perilsFloodCoastalData = perilsStats.reduce(
                (acc, stat, index) => {
                    if (perilsFloodCoastalStats[stat]) {
                        acc.push({
                            id: stat,
                            label: stat,
                            value: perilsFloodCoastalStats[stat],
                            color: perilsColors[index],
                        });
                    }
                    return acc;
                },
                []
            );
            setPerilsFloodCoastalData(perilsFloodCoastalData);
            // console.log("perilsFloodInData: ", perilsFloodCoastalData);
        }
        // Call setFields() after data is set
        setFields().then(() => {
            setBackdropOpen(false);
        });
    }, [data]);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        ...theme.typography.h6,
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
        // borderRadius: "2px",
        // boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        borderRadius: "1px",
        boxShadow: "0 1px 2px rgba(0, 0, 0, .1)",
    }));

    // Handle DataGrid columns and rows
    const tbl1_columns = [
        { field: "Policyholder:", headerName: "Policy Holder", width: 150 },
        { field: "Location:", headerName: "Location", width: 150 },
        {
            field: "Fire Classes Sum Insured:",
            headerName: "Fire Classes Sum Insured",
            width: 150,
        },
        {
            field: "EML:",
            headerName: "EML",
            width: 80,
            renderCell: (params) => {
                // Access the 'EML:' property using bracket notation
                let EML = params.row["EML:"];
                // if EML: is not formatted as %, calculate it and format it
                if (EML && !EML.includes("%")) {
                    let eml = Number(EML.replace(/,/g, ""));
                    if (isNaN(eml)) {
                        eml = 0;
                    }
                    return <span>{Math.round(eml / 100)}%</span>;
                }
            },
        },
    ];

    const tbl2_columns = [
        { field: "Policyholder:", headerName: "Policy Holder", width: 150 },
        { field: "Broker:", headerName: "Broker", width: 150 },
        { field: "Fire inception:", headerName: "Fire Inception", width: 90 },
        { field: "Fire load:", headerName: "Fire Load", width: 90 },
        { field: "Fire spread:", headerName: "Fire Spread", width: 90 },
        {
            field: "Perils - Explosion",
            headerName: "Perils Explosion",
            width: 90,
        },
        { field: "Perils - Fire", headerName: "Perils Fire", width: 90 },
        {
            field: "Perils - Flood (inland)",
            headerName: "Perils Flood (inland)",
            width: 90,
        },
        {
            field: "Perils - Flood (coastal)",
            headerName: "Perils Flood (coastal)",
            width: 90,
        },
        {
            field: "Fire Classes Sum Insured:",
            headerName: "Fire Classes Sum Insured",
            width: 150,
        },
        {
            field: "EML:",
            headerName: "EML",
            width: 80,
            renderCell: (params) => {
                // Access the 'EML:' property using bracket notation
                let EML = params.row["EML:"];
                // if EML: is not formatted as %, calculate it and format it
                if (EML && !EML.includes("%")) {
                    let eml = Number(EML.replace(/,/g, ""));
                    if (isNaN(eml)) {
                        eml = 0;
                    }
                    return <span>{Math.round(eml / 100)}%</span>;
                }
            },
        },
    ];

    return (
        <Box m="20px">
            <Header title="DASHBOARD" subtitle="Surveyed Risks" />

            <Card
                variant="outlined"
                sx={{
                    marginTop: "40px",
                    borderTop: 3,
                    borderTopColor: colors.greenAccent[400],
                    borderRadius: 0,
                }}
            >
                <CardContent>
                    <Stack spacing={2}>
                        {/* **************************** STACK 1: Filters ********************************** */}
                        <Item style={{ zIndex: 1001 }}>
                            {" "}
                            {/* zIndex: 1001 to make sure filters dropdown menu is in front of maps*/}
                            {/* ***************************************** ROW 1 OF FILTERS ******************************************* */}
                            <Grid container spacing={2} marginBottom={2}>
                                <Grid item md={3}>
                                    <MultiSelect
                                        options={policyHolders}
                                        onSelectedOptionsChange={
                                            setSelectedFilters
                                        }
                                        dataKey="Policyholder:"
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <MultiSelect
                                        options={brokers}
                                        onSelectedOptionsChange={
                                            setSelectedFilters
                                        }
                                        dataKey="Broker:"
                                    />
                                </Grid>
                                <Grid item md={2}>
                                    <MultiSelect
                                        options={fireInceptionData}
                                        onSelectedOptionsChange={
                                            setSelectedFilters
                                        }
                                        dataKey="Fire inception:"
                                    />
                                </Grid>
                                <Grid item md={2}>
                                    <MultiSelect
                                        options={fireLoadData}
                                        onSelectedOptionsChange={
                                            setSelectedFilters
                                        }
                                        dataKey="Fire load:"
                                    />
                                </Grid>
                                <Grid item md={2}>
                                    <MultiSelect
                                        options={fireSpreadData}
                                        onSelectedOptionsChange={
                                            setSelectedFilters
                                        }
                                        dataKey="Fire spread:"
                                    />
                                </Grid>
                            </Grid>
                            {/* ***************************************** ROW 2 OF FILTERS ******************************************* */}
                            <Grid container spacing={2}>
                                <Grid item md={3}>
                                    <MultiSelect
                                        options={perilsFireData}
                                        onSelectedOptionsChange={
                                            setSelectedFilters
                                        }
                                        dataKey="Perils - Fire"
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <MultiSelect
                                        options={perilsExplosionData}
                                        onSelectedOptionsChange={
                                            setSelectedFilters
                                        }
                                        dataKey="Perils - Explosion"
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <MultiSelect
                                        options={perilsFloodInlandData}
                                        onSelectedOptionsChange={
                                            setSelectedFilters
                                        }
                                        dataKey="Perils - Flood (inland)"
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <MultiSelect
                                        options={perilsFloodCoastalData}
                                        onSelectedOptionsChange={
                                            setSelectedFilters
                                        }
                                        dataKey="Perils - Flood (coastal)"
                                    />
                                </Grid>
                            </Grid>
                        </Item>
                        {/* **************************** STACK 2: Totals ********************************** */}
                        <Item>
                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={4} md={4}>
                                    <Item
                                        sx={{
                                            background: colors.greenAccent[800],
                                        }}
                                    >
                                        <Typography variant="h5">
                                            Number of Policyholders
                                        </Typography>
                                        <Typography variant="h3">
                                            {policyHolders.length}
                                        </Typography>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <Item
                                        sx={{
                                            background: colors.greenAccent[800],
                                        }}
                                    >
                                        <Typography variant="h5">
                                            Number of Brokers
                                        </Typography>
                                        <Typography variant="h3">
                                            {brokers.length}
                                        </Typography>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <Item
                                        sx={{
                                            background: colors.greenAccent[800],
                                        }}
                                    >
                                        <Typography variant="h5">
                                            Total Fire Classes Sum Insured
                                        </Typography>
                                        <Typography variant="h3">
                                            {zarFormatter.format(
                                                totalFireClassesSum
                                            )}
                                        </Typography>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                        {/* **************************** STACK 3: Map and Small Table ********************************** */}
                        <Item>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <GoogleMaps locations={locations} />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>
                                    <DataGrid
                                        rows={data}
                                        columns={tbl1_columns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    page: 0,
                                                    pageSize: 7,
                                                },
                                            },
                                        }}
                                        pageSizeOptions={[5, 7, 10]}
                                    />
                                </Grid>
                            </Grid>
                        </Item>
                        {/* **************************** STACK 4: Pie chart 1 ********************************** */}
                        <Item>
                            <Grid container spacing={2} marginBottom={3}>
                                <Grid item xs={12} sm={4} md={4}>
                                    <Item>
                                        <Stack spacing={2}>
                                            <Typography
                                                variant="h5"
                                                textAlign={"start"}
                                            >
                                                Fire inception
                                            </Typography>
                                            <Box height="50vh" width="100%">
                                                <PieChart
                                                    data={fireInceptionData}
                                                />
                                            </Box>
                                        </Stack>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <Item>
                                        <Stack spacing={2}>
                                            <Typography
                                                variant="h5"
                                                textAlign={"start"}
                                            >
                                                Fire load
                                            </Typography>
                                            <Box height="50vh" width="100%">
                                                <PieChart data={fireLoadData} />
                                            </Box>
                                        </Stack>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <Item>
                                        <Stack spacing={2}>
                                            <Typography
                                                variant="h5"
                                                textAlign={"start"}
                                            >
                                                Fire spread
                                            </Typography>
                                            <Box height="50vh" width="100%">
                                                <PieChart
                                                    data={fireSpreadData}
                                                />
                                            </Box>
                                        </Stack>
                                    </Item>
                                </Grid>
                            </Grid>
                            {/* *********************************** Perils ***************************************** */}
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Item>
                                        <Stack spacing={2}>
                                            <Typography
                                                variant="h5"
                                                textAlign={"start"}
                                            >
                                                Perils - Fire
                                            </Typography>
                                            <Box height="50vh" width="100%">
                                                <PieChart
                                                    data={perilsFireData}
                                                />
                                            </Box>
                                        </Stack>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Item>
                                        <Stack spacing={2}>
                                            <Typography
                                                variant="h5"
                                                textAlign={"start"}
                                            >
                                                Perils - Explosion
                                            </Typography>
                                            <Box height="50vh" width="100%">
                                                <PieChart
                                                    data={perilsExplosionData}
                                                />
                                            </Box>
                                        </Stack>
                                    </Item>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Item>
                                        <Stack spacing={2}>
                                            <Typography
                                                variant="h5"
                                                textAlign={"start"}
                                            >
                                                Perils - Flood (inland)
                                            </Typography>
                                            <Box height="50vh" width="100%">
                                                <PieChart
                                                    data={perilsFloodInlandData}
                                                />
                                            </Box>
                                        </Stack>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Item>
                                        <Stack spacing={2}>
                                            <Typography
                                                variant="h5"
                                                textAlign={"start"}
                                            >
                                                Perils - Flood (coastal)
                                            </Typography>
                                            <Box height="50vh" width="100%">
                                                <PieChart
                                                    data={
                                                        perilsFloodCoastalData
                                                    }
                                                />
                                            </Box>
                                        </Stack>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                        {/* **************************** STACK 6: Big Table ********************************** */}
                        <Item>
                            <DataGrid
                                rows={data}
                                columns={tbl2_columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            page: 0,
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5, 10, 15, 20, 50]}
                            />
                        </Item>
                    </Stack>
                    <Backdrop
                        sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={backdropOpen}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </CardContent>
            </Card>
            <Snackbar
                open={snackBarOpen}
                autoHideDuration={6000}
                onClose={closeSnackbar}
            >
                <Alert
                    onClose={closeSnackbar}
                    severity="warning"
                    color="warning"
                    sx={{ width: "100%" }}
                >
                    {snackBarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default SurveyDashboard;
