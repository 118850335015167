import * as React from "react";
import {
    Box,
    Grid,
    Backdrop,
    CircularProgress,
    Button,
    Typography,
    useTheme,
    Divider,
    Tab,
    Tabs,
} from "@mui/material";
import { useState, useEffect } from "react";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import InspectionType from "./InspectionType";
import axiosClient from "../../axios";
import InspectionDetails from "./InspectionDetails";
import { set } from "lodash";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import prettyDate from "../../formatdate";
import { debounce } from "lodash";
import { el } from "date-fns/locale";

export default function AllInspections() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [inspections, setInspections] = useState([]); // Send this to InspectionType (Parent to Child)
    const [selectedInspection, setSelectedInspection] = useState(null); // Get this from InspectionType (Child to Parent)
    const [displayInspectionID, setDisplayInspectionID] = useState(null); // Send this to InspectionDetails (Parent to Child)
    const [backdropOpen, setBackdropOpen] = useState(false);

    const handleTabChange = (e, tabIndex) => {
        setInspections([]);
        setSelectedInspection(null);
        setDisplayInspectionID(null);
        setCurrentTabIndex(tabIndex);
    };

    useEffect(
        debounce(() => {
            setBackdropOpen(true);
            async function getInspections() {
                let api_path = "";
                if (currentTabIndex === 0) {
                    api_path = "/inspections/all_active_inspections";
                } else if (currentTabIndex === 1) {
                    api_path = "/inspections/all_completed_inspections";
                } else if (currentTabIndex === 2) {
                    api_path = "/inspections/all_archived_inspections";
                } else if (currentTabIndex === 3) {
                    api_path = "/inspections/get_all_new_inspections";
                }
                const response = await axiosClient.get(api_path);
                // Loop through object of response.data and create a new array on inspections
                const loaded_inspections = [];
                for (const key in response.data) {
                    // Store in loaded_inspections
                    loaded_inspections.push({
                        id: response.data[key].id,
                        selectItem:
                            response.data[key].audit_policy_holder +
                            " - " +
                            response.data[key].audit_broker_name +
                            " - " +
                            prettyDate(response.data[key].audit_survey_date),
                    });
                }
                setInspections(loaded_inspections);
                setBackdropOpen(false);
                // console.log("Loaded Inspections: ", loaded_inspections);
            }
            getInspections();
        }, 1000), // Debounce delay of 1000 milliseconds
        [currentTabIndex] // On change of currentTabIndex, run useEffect
    );

    // Get Inspection ID from InspectionType (Child to Parent)
    const getSelectedInspection = (inspectionDetails) => {
        setSelectedInspection(inspectionDetails);
        setDisplayInspectionID(inspectionDetails.id);
    };

    return (
        <Box m="20px">
            <Header
                title="INSPECTIONS"
                subtitle="Search for survey details by selecting appropriate tabs below."
            />
            <Card
                variant="outlined"
                sx={{
                    marginTop: "40px",
                    borderTop: 3,
                    borderTopColor: colors.greenAccent[400],
                    borderRadius: 0,
                }}
            >
                <CardContent>
                    <Tabs
                        value={currentTabIndex}
                        onChange={handleTabChange}
                        indicatorColor="secondary"
                        textColor="secondary"
                    >
                        <Tab label="By Incomplete Requirements" />
                        <Tab label="By Completed Requirements" />
                        <Tab label="By Archived Surveys" />
                        <Tab label="By Fetched Surveys" />
                    </Tabs>
                    {/* TAB 1 (Incomplete Requirements) Contents */}
                    {currentTabIndex === 0 && (
                        <>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <InspectionType
                                        inspections={inspections}
                                        getSelectedInspection={
                                            getSelectedInspection
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Divider />{" "}
                            <Box paddingBottom={2} sx={{ flexGrow: 1 }}>
                                <InspectionDetails
                                    inspectionID={displayInspectionID}
                                    currentIndexTab={currentTabIndex}
                                />
                            </Box>
                        </>
                    )}

                    {/* TAB 2(Completed Requirements) Contents */}
                    {currentTabIndex === 1 && (
                        <>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <InspectionType
                                        inspections={inspections}
                                        getSelectedInspection={
                                            getSelectedInspection
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Box>
                                <InspectionDetails
                                    inspectionID={displayInspectionID}
                                    currentIndexTab={currentTabIndex}
                                />
                            </Box>
                        </>
                    )}

                    {/* TAB 3(Archived Requirements) Contents */}
                    {currentTabIndex === 2 && (
                        <>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <InspectionType
                                        inspections={inspections}
                                        getSelectedInspection={
                                            getSelectedInspection
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Box>
                                <InspectionDetails
                                    inspectionID={displayInspectionID}
                                    currentIndexTab={currentTabIndex}
                                />
                            </Box>
                        </>
                    )}

                    {/* TAB 4(Loaded Surveys(Fetched)) Contents */}
                    {currentTabIndex === 3 && (
                        <>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <InspectionType
                                        inspections={inspections}
                                        getSelectedInspection={
                                            getSelectedInspection
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Box>
                                <InspectionDetails
                                    inspectionID={displayInspectionID}
                                    currentIndexTab={currentTabIndex}
                                />
                            </Box>
                        </>
                    )}

                    {/* Render Backdrop */}
                    <Backdrop
                        sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={backdropOpen}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </CardContent>
            </Card>
        </Box>
    );
}
