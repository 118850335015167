import {
    Box,
    Button,
    Typography,
    useTheme,
    TextField,
    Backdrop,
    CircularProgress,
    Snackbar,
    Alert,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Divider,
    IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axiosClient from "../../axios";
import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { compareAsc, format } from "date-fns";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Link, useNavigate } from "react-router-dom";

const NextInspection = () => {
    let reqCount = 0;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [snackBarOpen, setSnackBarOpen] = useState(false);

    const [inspection, setInspection] = useState({});
    const [initialValues, setInitialValues] = useState({});
    const [requirements, setRequirements] = useState([]);

    const closeSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarOpen(false);
    };

    // Get the next inspection using the API
    useEffect(() => { 
        setBackdropOpen(true);
        async function getInspection() {
            const response = await axiosClient.get(
                "inspections/get_next_inspection"
            );
            // If response.data is not empty, then set the inspection state
            if (Object.keys(response.data).length !== 0) {
                console.log("Response: ", response.data);
                setInspection(response.data);
                // Convert requirements to an array
                // Convert response.data.requirements to an array
                let reqArray = [];
                for (const [key, value] of Object.entries(
                    response.data.requirements
                )) {
                    reqArray.push(value);
                }
                setRequirements(reqArray);
                setInitialValues({
                    audit_id: response.data.audit_id,
                    template_id: response.data.template_id,
                    audit_created_at: response.data.audit_created_at,
                    audit_modified_at: response.data.audit_modified_at,
                    // Above should be hidden
                    audit_surveyor_name: response.data.audit_surveyor_name,
                    audit_survey_date: response.data.audit_survey_date,
                    audit_account_status: response.data.audit_account_status,
                    audit_policy_number: response.data.audit_policy_number,
                    audit_policy_holder: response.data.audit_policy_holder,
                    audit_contact_name: response.data.audit_contact_name,
                    audit_contact_number: response.data.audit_contact_number,
                    audit_broker_name: response.data.audit_broker_name,
                    audit_broker_contact_number:
                        response.data.audit_broker_contact_number,
                    audit_broker_email: response.data.audit_broker_email,
                    audit_insurer_name: response.data.audit_insurer_name,
                    audit_insurer_email: response.data.audit_insurer_email,
                    audit_physical_address:
                        response.data.audit_physical_address,
                    audit_google_location: response.data.audit_google_location,
                    audit_web_report_link: response.data.audit_web_report_link,
                });
                // console.log("Initial values: ", initialValues);
                setBackdropOpen(false);
            }
        }
        getInspection();
    }, []);
    // console.log("Requirements: ", requirements);

    // Handle form submission
    const handleFormSubmit = (values) => {
        // console.log("VALUES: ", values);
        async function createInspection() {
            const response = await axiosClient
                .post("/inspections", {
                    audit_id: values.audit_id,
                    template_id: values.template_id,
                    audit_created_at: values.audit_created_at,
                    audit_modified_at: values.audit_modified_at,
                    audit_surveyor_name: values.audit_surveyor_name,
                    audit_survey_date: values.audit_survey_date,
                    audit_account_status: values.audit_account_status,
                    audit_policy_number: values.audit_policy_number,
                    audit_policy_holder: values.audit_policy_holder,
                    audit_contact_name: values.audit_contact_name,
                    audit_contact_number: values.audit_contact_number,
                    audit_broker_name: values.audit_broker_name,
                    audit_broker_contact_number:
                        values.audit_broker_contact_number,
                    audit_broker_email: values.audit_broker_email,
                    audit_insurer_name: values.audit_insurer_name,
                    audit_insurer_email: values.audit_insurer_email,
                    audit_physical_address: values.audit_physical_address,
                    audit_google_location: values.audit_google_location,
                    audit_web_report_link: values.audit_web_report_link,
                })
                .then((response) => {
                    // console.log("RESPONSE: ", response);
                    if (response.status === 201) {
                        // console.log("Inspection created successfully!");
                        // Insert requirement for this inspection
                        const audit_id = response.data.audit_id;
                        // console.log("Audit ID: ", audit_id);
                        // Loop through requirements and insert them into the database via API
                        requirements
                            .map((requirement) => {
                                async function inserRequirement() {
                                    const response = await axiosClient
                                        .post("/inspections/requirements", {
                                            audit_id: audit_id,
                                            requirement_details: requirement,
                                        })
                                        .then((response) => {
                                            // console.log("RESPONSE: ", response);
                                            if (response.status === 201) {
                                                // console.log("Requirement created successfully!");
                                            } else {
                                                // console.log("Error while creating requirement user!");
                                            }
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                }
                                inserRequirement();
                            })
                            .join("");
                        // Send an email to the insurer
                        // Make a POST request to send email
                        axiosClient
                            .post("/send_email", {
                                // "recipient_email": audit_insurer_email.audit_broker_email,
                                // juan@occusurv.co.za
                                recipient_email: "dacubantoane@gmail.com",
                                subject:
                                    "Survey for a new inspection - " +
                                    inspection.audit_policy_number,
                                body:
                                    "Dear " +
                                    inspection.audit_contact_name +
                                    ",<br /><br />" +
                                    "We have completed the survey with the following details, please specify whether this inspection is a quote or based on an active account:" +
                                    "<br /><br />" +
                                    "Policy Number: " +
                                    inspection.audit_policy_number +
                                    "<br />" +
                                    "Policy Holder: " +
                                    inspection.audit_policy_holder +
                                    "<br />" +
                                    "Insurer Name: " +
                                    inspection.audit_insurer_name +
                                    "<br />" +
                                    "Contact Number: " +
                                    inspection.audit_contact_number +
                                    "<br />" +
                                    "Survey Date: " +
                                    inspection.audit_survey_date +
                                    "<br />" +
                                    "Surveyor Name: " +
                                    inspection.audit_surveyor_name +
                                    "<br />" +
                                    "Survey Link: " +
                                    inspection.audit_web_report_link +
                                    "<br /><br />" +
                                    "Please specify whether this inspection is a quote or based on an active account:" +
                                    "<br />" +
                                    "<input type='radio' name='status' value='active' /> Active " +
                                    "<input type='radio' name='status' value='accepted' /> Quote Accepted " +
                                    "<input type='radio' name='status' value='rejected' /> Quote Rejected " +
                                    "<br /><br />" +
                                    "Please specify which requirements should be put forth for the broker to implement: " +
                                    "<br />" +
                                    // Loop through each requirement and create checkboxes
                                    requirements
                                        .map((requirement) => {
                                            return (
                                                "<input type='checkbox' name='requirement' value='some_id' /> " +
                                                requirement +
                                                "<br />"
                                            );
                                        })
                                        .join("") +
                                    "<br /><br />" +
                                    "Thank you for your cooperation." +
                                    "<br /><br />" +
                                    "Sincerely," +
                                    "<br />" +
                                    inspection.audit_surveyor_name,
                            })
                            .then((response) => {
                                // console.log("Response: ", response);
                            })
                            .catch((error) => {
                                console.log("Error: ", error);
                            });
                        // Open SnackBar
                        setSnackBarOpen(true);
                        // Reset data
;
                    } else {
                        console.log("Error while creating Inspection!");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        createInspection();
    };

    const navigate = useNavigate();
    const handleRefresh = () => {
        console.log("Refresh button clicked!");
        navigate("/sync_inspection", { replace: true });
    };
    const setReqCount = (count) => {
        reqCount = 1
    }

    return (
        <Box m="20px">
            <Header
                title="INSPECTION DETAILS"
                subtitle="Get the next available inspection from SafetyCulture and sync it to the portal"
            />
            {/* Show this form once inspection data is read from API and available */}
            {Object.keys(inspection).length !== 0 && (
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                display="grid"
                                gap="30px"
                                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                sx={{
                                    "& > div": {
                                        gridColumn: isNonMobile
                                            ? undefined
                                            : "span 4",
                                    },
                                }}
                            >
                                {/* *********************************************ROW 1 ************************************************** */}
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Surveyor name:"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.audit_surveyor_name}
                                    name="audit_surveyor_name"
                                    sx={{ gridColumn: "span 1" }}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Survey Date:"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.audit_survey_date}
                                    name="audit_survey_date"
                                    sx={{ gridColumn: "span 1" }}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Account Status:"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.audit_account_status}
                                    name="audit_account_status"
                                    sx={{ gridColumn: "span 1" }}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Policy Number:"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.audit_policy_number}
                                    name="audit_policy_number"
                                    sx={{ gridColumn: "span 1" }}
                                />
                                {/* *********************************************ROW 2************************************************** */}
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Policy Holder:"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.audit_policy_holder}
                                    name="audit_policy_holder"
                                    sx={{ gridColumn: "span 1" }}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Contact Person Name:"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.audit_contact_name}
                                    name="audit_contact_name"
                                    sx={{ gridColumn: "span 1" }}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Contact Person Number:"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.audit_contact_number}
                                    name="audit_contact_number"
                                    sx={{ gridColumn: "span 1" }}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Broker Name:"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.audit_broker_name}
                                    name="audit_broker_name"
                                    sx={{ gridColumn: "span 1" }}
                                />
                                {/* *********************************************ROW 3 ************************************************** */}
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Broker Contact Number:"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.audit_broker_contact_number}
                                    name="audit_broker_contact_number"
                                    sx={{ gridColumn: "span 1" }}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Broker Email:"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.audit_broker_email}
                                    name="audit_broker_email"
                                    sx={{ gridColumn: "span 1" }}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Insurer Name:"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.audit_insurer_name}
                                    name="audit_insurer_name"
                                    sx={{ gridColumn: "span 1" }}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Insurer Email:"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.audit_insurer_email}
                                    name="audit_insurer_email"
                                    sx={{ gridColumn: "span 1" }}
                                />
                                {/* *********************************************ROW 4 ************************************************** */}
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Physical Address:"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.audit_physical_address}
                                    name="audit_physical_address"
                                    sx={{ gridColumn: "span 2" }}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Google Location:"
                                    InputProps={{ readOnly: true }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.audit_google_location}
                                    name="audit_google_location"
                                    sx={{ gridColumn: "span 2" }}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Survey Report Link:"
                                    InputProps={{ readOnly: true }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.audit_web_report_link}
                                    name="audit_web_report_link"
                                    sx={{ gridColumn: "span 4" }}
                                />
                            </Box>
                            {/* ********************************************* REQUIREMENTS ************************************************** */}
                            <Box sx={{ paddingTop: "5px" }}>
                                {requirements && (
                                    <TableContainer component={Paper}>
                                        <Table
                                            sx={{ minWidth: 450 }}
                                            aria-label="simple table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        sx={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        No.
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Requiremt desctiption
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {setReqCount(0)}
                                                {Object.values(
                                                    requirements
                                                ).map((item, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{
                                                            "&:last-child td, &:last-child th":
                                                                {
                                                                    border: 0,
                                                                },
                                                        }}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            {
                                                                (reqCount ++ )
                                                            }
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            {item}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}

                                {/* If No requirements available */}
                                {(!requirements ||
                                    requirements.length === 0) && (
                                    <Typography>
                                        No requirements found for this
                                        inspection
                                    </Typography>
                                )}
                            </Box>

                            <Box
                                display="flex"
                                justifyContent="end"
                                mt="20px"
                                mb="20px"
                            >
                                <Button
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                >
                                    Create Inspection
                                </Button>
                            </Box>
                            {/* <Box
                                display="flex"
                                justifyContent="end"
                            >
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={handleRefresh}
                                >
                                    <RefreshIcon > Refresh</RefreshIcon>
                                </Button>
                            </Box> */}
                        </form>
                    )}
                </Formik>
            )}

            {/* Render Backdrop */}
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={snackBarOpen}
                autoHideDuration={6000}
                onClose={closeSnackbar}
            >
                <Alert
                    onClose={closeSnackbar}
                    severity="success"
                    color="success"
                    sx={{ width: "100%" }}
                >
                    Inspection successfully created and an initial email sent to
                    the insurer!
                </Alert>
            </Snackbar>
        </Box>
    );
};
export default NextInspection;
