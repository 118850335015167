import React from "react";
import {
    Box,
    Button,
    Typography,
    useTheme,
    Backdrop,
    CircularProgress,
    Grid,
} from "@mui/material";
import { useState, useEffect } from "react";
import { tokens } from "../../theme";
import axiosClient from "../../axios";
import Header from "../../components/Header";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useFormik } from "formik";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { DatePicker, Space } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import MultiHorizontalSelect from "../../components/MultiHorizontalSelect";
import { set } from "lodash";

export default function InvoiceableSurveys() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { RangePicker } = DatePicker;

    const [inspections, setInspections] = useState([]);
    const [exportInspections, setExportInspections] = useState([]);
    const [insurances, setInsurances] = useState([]);
    const [selectedInsurances, setSelectedInsurances] = useState([]);
    const [invoiceDatesRange, setInvoiceDatesRange] = useState([]);
    const [initialLoad, setInitialLoad] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);

    const handleInvoiceDateChange = (dates) => {
        setInvoiceDatesRange(dates);
    };

    const formik = useFormik({
        initialValues: {
            selected: {},
        },
        onSubmit: (values) => {
            const selectedData = exportInspections
                .filter((item) => values.selected[item.id])
                .map((item) => ({
                    audit_insurer_name: item.audit_insurer_name,
                    audit_broker_name: item.audit_broker_name,
                    audit_policy_holder: item.audit_policy_holder,
                    audit_policy_number: item.audit_policy_number,
                    audit_survey_date: new Date(item.audit_survey_date)
                        .toISOString()
                        .split("T")[0],
                    reminder_start_date: item.reminder_start_date,
                }));

            const ws = XLSX.utils.json_to_sheet(selectedData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            const excelBuffer = XLSX.write(wb, {
                bookType: "xlsx",
                type: "array",
            });
            const blob = new Blob([excelBuffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
            });
            const today = new Date();
            const formattedDate = `${today.getFullYear()}-${String(
                today.getMonth() + 1
            ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;
            saveAs(blob, `Invoice_surveys_${formattedDate}.xlsx`);
        },
    });

    const searchInvoiceableInspections = () => {
        // console.log("Dates[0]: ", dayjs(invoiceDatesRange?.[0] ).format("YYYY-MM-DD"));
        // console.log("Dates[1]: ", dayjs(invoiceDatesRange?.[1] ).format("YYYY-MM-DD"));
        // If invoiceDatesRange is empty, return
        if (invoiceDatesRange.length === 0) {
            return;
        }
        setBackdropOpen(true);
        setInspections([]);
        async function getInspections() {
            const response = await axiosClient.get(
                `/inspections/invoiceable_inspections/${dayjs(
                    invoiceDatesRange?.[0]
                ).format("YYYY-MM-DD")}/${dayjs(invoiceDatesRange?.[1]).format(
                    "YYYY-MM-DD"
                )}`
            );
            if (response.data.length > 0) {
                // console.log(response.data);
                setInspections(response.data);
                setExportInspections(response.data);

                // Generate unique list of audit_insurer_name
                const uniqueInsurerNames = [
                    ...new Set(
                        response.data.map((item) => item.audit_insurer_name)
                    ),
                ];
                // console.log(uniqueInsurerNames);
                setInsurances(uniqueInsurerNames);

                formik.setFieldValue(
                    "selected",
                    response.data.reduce(
                        (acc, curr) => ({ ...acc, [curr.id]: true }),
                        {}
                    )
                );

                setInitialLoad(true);
            }

            setBackdropOpen(false);
        }
        getInspections();
    };

    // Everytime selectedInsurances changes, update the inspections list
    useEffect(() => {
        // console.log("Selected Insurances: ", selectedInsurances);
        if (selectedInsurances.length > 0) {
            setInitialLoad(false);
            const selectedInsuranceNames = selectedInsurances.map(insurance => insurance.value);
            const filteredInspections = inspections.filter((item) =>
                selectedInsuranceNames.includes(item.audit_insurer_name)
            );
            setExportInspections(filteredInspections); // Update exportInspections
        } else {
            setInspections([]);
            setExportInspections([]); // Update exportInspections
        }
    }, [selectedInsurances]);

    return (
        <Box m="20px">
            <Header
                title="SURVEYS"
                subtitle="Survey requirements which are marked active or accepted quote and were sent to the broker "
            />

            <Card
                variant="outlined"
                sx={{
                    marginTop: "40px",
                    borderTop: 3,
                    borderTopColor: colors.greenAccent[400],
                    borderRadius: 0,
                }}
            >
                <CardContent>
                    <Box sx={{ minHeight: '480px' }}>
                        <Grid container spacing={2} sx={{ paddingBottom: 2 }} >
                            <Grid item xs={12} md={6}>
                                <RangePicker
                                    allowClear={false}
                                    value={
                                        invoiceDatesRange.length > 0
                                            ? invoiceDatesRange
                                            : ""
                                    }
                                    onChange={handleInvoiceDateChange}
                                    showTime={false}
                                />
                                <Button>
                                    <SearchIcon
                                        onClick={searchInvoiceableInspections}
                                    />
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MultiHorizontalSelect
                                    options={insurances.map((item) => ({
                                        label: item,
                                        value: item,
                                    }))}
                                    onSelectedValues={setSelectedInsurances}
                                />
                            </Grid>
                        </Grid>
                    {(initialLoad ? inspections.length : exportInspections.length) > 0 && (
                        <form onSubmit={formik.handleSubmit}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Select</th>
                                        <th>Insurer Name</th>
                                        <th>Broker Name</th>
                                        <th>Policy Holder</th>
                                        <th>Policy Number</th>
                                        <th>Survey Date</th>
                                        <th>Email Sent Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(initialLoad ? inspections : exportInspections).map((item) => (
                                        <tr key={item.id}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    name={`selected.${item.id}`}
                                                    checked={
                                                        formik.values.selected[
                                                            item.id
                                                        ]
                                                    }
                                                />
                                            </td>
                                            <td>{item.audit_insurer_name}</td>
                                            <td>{item.audit_broker_name}</td>
                                            <td>{item.audit_policy_holder}</td>
                                            <td>{item.audit_policy_number}</td>
                                            <td>
                                                {
                                                    new Date(
                                                        item.audit_survey_date
                                                    )
                                                        .toISOString()
                                                        .split("T")[0]
                                                }
                                            </td>
                                            <td>{item.reminder_start_date}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Export to Excel
                            </Button>
                        </form>
                    )}
                    </Box>
                </CardContent>
            </Card>
            {/* Render Backdrop */}
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}
