import React from "react";
import { useEffect, useRef } from "react";
import GoogleMapReact from "google-map-react";
import { Typography } from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import locationSVG from "./location.svg";

export default function GoogleMaps({ locations }) {
    // Share the Google sheets with the following email address: ocuusurveserviceacc@velvety-folder-404721.iam.gserviceaccount.com
    // Create a new Leaflet icon with the SVG path
    const customLocIcon = new Icon({
        iconUrl: locationSVG,
        iconSize: [25, 41], // size of the icon
    });
    return (
        <div style={{ height: "500px", width: "100%" }}>
            <MapContainer
                center={[-33.89685, 84.8167]}
                zoom={2}
                scrollWheelZoom={false}
                style={{ height: "100%", width: "100%" }}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {locations.map((locationObj, index) => {
                    // Check if locationObj.location is a string and contains a newline character
                    if (
                        typeof locationObj.location === "string" &&
                        locationObj.location.includes("\n")
                    ) {
                        // Extract coordinates from the location string
                        const matches = locationObj.location
                            .split("\n")[1]
                            .match(/[-]?\d+\.\d+/g);
                        if (matches) {
                            const coordinates = matches.map(Number);
                            // If coordinates array has exactly two elements, return a Marker
                            if (coordinates && coordinates.length === 2) {
                                return (
                                    <Marker
                                        key={index}
                                        position={coordinates}
                                        icon={customLocIcon}
                                    >
                                        <Popup>
                                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                                {locationObj.policyholder}
                                            </Typography>
                                            <Typography variant="body1">
                                                Fire Classes Sum Insured: {locationObj.fireClassesSum}
                                            </Typography>
                                        </Popup>
                                    </Marker>
                                );
                            }
                        }
                    }
                })}
            </MapContainer>
        </div>
    );
}
