import React, { Component } from "react";
import { DropzoneArea } from "mui-file-dropzone";

// https://github.com/Deckstar/mui-file-dropzone

class FireLossDropzone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
        };
    }
    handleChange(files) {
        this.setState({
            files: files,
        });
        // Pass the uploaded files to the parent component via the setFiles prop
        this.props.setFiles(files);
    }
    render() {
        return (
            <DropzoneArea
                onChange={this.handleChange.bind(this)}
                acceptedFiles={["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                filesLimit={1}
                maxFileSize={5000000}
                dropzoneText={"Drag and drop fire loss statistics excel(xlsx) file not exceeding 5mb here or click"}
            />
        );
    }
}

export default FireLossDropzone;
