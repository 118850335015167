import * as React from "react";
import {
    Box,
    Grid,
    Paper,
    Button,
    Typography,
    useTheme,
    Divider,
    Tab,
    Tabs,
} from "@mui/material";
import { useState, useEffect } from "react";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import InspectionType from "./InspectionType";
import axiosClient from "../../axios";
import InspectionDetails from "./InspectionDetails";
import { set } from "lodash";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import prettyDate from "../../formatdate";

export default function ArchivedSurveys() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [inspections, setInspections] = useState([]); // Send this to InspectionType (Parent to Child)
    const [selectedInspection, setSelectedInspection] = useState(null); // Get this from InspectionType (Child to Parent)
    const [displayInspectionID, setDisplayInspectionID] = useState(null); // Send this to InspectionDetails (Parent to Child)

    useEffect(() => {
        // console.log("Current Tab Index: ", currentTabIndex);
        async function getInspections() {
            const response = await axiosClient.get(
                "/inspections/archived_inspections"
            );
            // Loop through object of response.data and create a new array on inspections
            const loaded_inspections = [];
            for (const key in response.data) {
                loaded_inspections.push({
                    id: response.data[key].id,
                    selectItem:
                        response.data[key].audit_policy_holder +
                        " - " +
                        response.data[key].audit_broker_name +
                        " - " +
                        prettyDate(response.data[key].audit_survey_date),
                });
            }
            setInspections(loaded_inspections);
            // console.log("Loaded Inspections: ", loaded_inspections);
        }
        getInspections();
    }, []);

    // Get Inspection ID from InspectionType (Child to Parent)
    const getSelectedInspection = (inspectionDetails) => {
        setSelectedInspection(inspectionDetails);
        setDisplayInspectionID(inspectionDetails.id);
    };
    // console.log("Inspection ID: ", selectedInspection);

    return (
        <Box m="20px">
            <Header
                title="SURVEYS"
                subtitle="Archived surveys marked as quote"
            />
            <Card
                variant="outlined"
                sx={{
                    marginTop: "40px",
                    borderTop: 3,
                    borderTopColor: colors.greenAccent[400],
                    borderRadius: 0,
                }}
            >
                <CardContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <InspectionType
                                inspections={inspections}
                                getSelectedInspection={getSelectedInspection}
                            />
                        </Grid>
                    </Grid>
                    <Divider />{" "}
                    <Box paddingBottom={2} sx={{ flexGrow: 1 }}>
                        <InspectionDetails
                            inspectionID={displayInspectionID}
                            currentIndexTab={undefined}
                        />
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}
