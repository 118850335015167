import * as React from "react";
import { useTheme, Autocomplete, TextField, Divider, Box, Backdrop, CircularProgress,} from "@mui/material";
import { useState, useEffect } from "react";
import { tokens } from "../../theme";
import { debounce, set } from 'lodash';

const InspectionType = ({ inspections, getSelectedInspection }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selectedInspection, setSelectedInspection] = useState(null);
    const [backdropOpen, setBackdropOpen] = useState(false);

    const handleChange = debounce((event, id) => {
        setBackdropOpen(true);
        if (id !== undefined) {
            // Set the selectedInspection state variable to the value of the id argument
            setSelectedInspection(id);
            // Pass the id argument to the getInspectionID function
            getSelectedInspection(id);
        }
        setBackdropOpen(false);
    }, 500); // delay in milliseconds

    return (
        <Box
        marginTop={2}
        marginBottom={2}
        sx={{
            bgcolor: colors.primary[400],
            // borderLeft: "1px solid",
            // borderRight: "1px solid",
        }}
        >
            <Autocomplete
                style={{ width: "50%" }}
                disableClearable
                onChange={handleChange}
                value={selectedInspection}
                options={inspections}
                getOptionLabel={(option) => option.selectItem}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                    <TextField
                        label="Search by Policyholder or broker, then select to view detailed information about the survey"
                        {...params}
                        variant="standard"
                    />
                )}
                sx={{paddingLeft: "10px"}}
            ></Autocomplete>

            {/* Render Backdrop */}
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
};

export default InspectionType;
