import React from "react";
import { Typography } from "@mui/material";
import Select from "react-dropdown-select";

export default function SingleSelect({
    options,
    selectedValueIndex,
    onSelectedValue,
    placeholder = "SelectIT...",
    label,
}) {
    return (
        <>
            <Select
                options={options}
                // Use selectedValueIndex as the index of the selected value in options array
                values={options[selectedValueIndex] ? [options[selectedValueIndex]] : []}
                onChange={(value) => {
                    onSelectedValue(value[0]?.value);
                }}
                placeholder={placeholder}
            />
            <Typography variant="body2" sx={{ textAlign: "start", fontWeight: "bold" }} >{label}</Typography>
        </>
    );
}
