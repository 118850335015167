import { Typography, Box, useTheme, IconButton } from "@mui/material";
import { tokens } from "../theme";

const Header = ({ title, subtitle }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <>
            <Box mb="5px" sx={{ bgcolor: colors.primary[400], borderLeft: "2px solid", borderLeftColor: colors.greenAccent[400] }}>
                <Typography
                    variant="h4"
                    color={colors.grey[100]}
                    fontWeight="bold"
                    // sx={{ m: "0 0 5px 0" }}
                    sx={{ paddingLeft: "10px", paddingTop: "5px" }}
                >
                    {title}
                </Typography>
                <Typography
                    variant="h5"
                    color={colors.greenAccent[901]}
                    sx={{ paddingLeft: "10px", paddingBottom: "5px" }}
                >
                    {subtitle}
                </Typography>
            </Box>
        </>
    );
};

export default Header;
