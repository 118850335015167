import React, {useState} from "react";
import Select from "react-dropdown-select"; //https://github.com/sanusart/react-dropdown-select
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

const CustomContentAndDropdown = (props) => {

    const customContentRenderer = ({ props, state }) => (
        <div style={{ cursor: "pointer" }}>
            {state.values.length} of {props.options.length} selected
        </div>
    );

    const customDropdownRenderer = ({ props, state, methods }) => {
        const regexp = new RegExp(state.search, "i");

        return (
            <div>
                <SearchAndToggle color={props.color}>
                    <Buttons>
                        <div>Search and select:</div>
                        {/* {methods.areAllSelected() ? (
                            <Button
                                className="clear"
                                onClick={methods.clearAll}
                            >
                                Clear all
                            </Button>
                        ) : (
                            <React.Fragment>
                                <Button onClick={methods.selectAll}>
                                    Select all
                                </Button>
                            </React.Fragment>
                        )} */}
                    </Buttons>
                    <input
                        type="text"
                        value={state.search}
                        onChange={methods.setSearch}
                        placeholder="Type to search"
                    />
                </SearchAndToggle>
                <Items>
                    {props.options
                        .filter((item) =>
                            regexp.test(
                                item[props.searchBy] || item[props.labelField]
                            )
                        )
                        .map((option) => {
                            if (
                                !props.keepSelectedInList &&
                                methods.isSelected(option)
                            ) {
                                return null;
                            }

                            return (
                                <Box
                                    key={option[props.valueField]}
                                    sx={{ textAlign: "start" }}
                                >
                                    <Item
                                        disabled={option.disabled}
                                        onClick={
                                            option.disabled
                                                ? null
                                                : () => methods.addItem(option)
                                        }
                                    >
                                        <input
                                            type="checkbox"
                                            onChange={() =>
                                                option.disabled
                                                    ? undefined
                                                    : methods.addItem(option)
                                            }
                                            checked={
                                                state.values.indexOf(option) !== -1
                                            }
                                        />
                                        <ItemLabel>
                                            {option[props.labelField]}
                                        </ItemLabel>
                                    </Item>
                                </Box>
                            );
                        })}
                </Items>
            </div>
        );
    };

    // Prepare the options
    const options = props.options.map((filter) => ({
        label: filter.label,
        value: filter.id,
    }));

    return (
        <div>
            <StyledSelect
                placeholder="Select"
                multi
                contentRenderer={customContentRenderer}
                dropdownRenderer={customDropdownRenderer}
                onChange={(value) => {
                    // Map over the value array and extract the label of each option
                    const labels = value.map((option) => option.label);
                    // Store the labels in an object with the key 'key'
                    const result = { [props.dataKey]: labels };
                    props.onSelectedOptionsChange(result);
                }}
                options={options}
                values={options} // Set the values prop to the same array as the options prop
            />
            <Box sx={{ textAlign: "start" }}>
                <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    color="text.secondary"
                >
                    {props.dataKey}
                </Typography>
            </Box>
        </div>
    );
};
const Title = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

const StyledSelect = styled(Select)`
    .react-dropdown-select-dropdown {
        overflow: initial;
    }
`;

const SearchAndToggle = styled.div`
    display: flex;
    flex-direction: column;

    input {
        margin: 10px 10px 0;
        line-height: 30px;
        padding: 0px 20px;
        border: 1px solid #ccc;
        border-radius: 3px;
        :focus {
            outline: none;
            border: 1px solid deepskyblue;
        }
    }
`;

const Items = styled.div`
    overflow: auto;
    min-height: 10px;
    max-height: 200px;
`;

const Item = styled.div`
    display: flex;
    margin: 10px;
    align-items: baseline;
    ${({ disabled }) => disabled && "text-decoration: line-through;"}
`;

const ItemLabel = styled.div`
    margin: 5px 10px;
`;

const Buttons = styled.div`
    display: flex;
    justify-content: space-between;

    & div {
        margin: 10px 0 0 10px;
        font-weight: 600;
    }
`;

const Button = styled.button`
    background: none;
    border: 1px solid #555;
    color: #555;
    border-radius: 3px;
    margin: 10px 10px 0;
    padding: 3px 5px;
    font-size: 10px;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;

    &.clear {
        color: tomato;
        border: 1px solid tomato;
    }

    :hover {
        border: 1px solid deepskyblue;
        color: deepskyblue;
    }
`;

export default CustomContentAndDropdown;
