import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";

const ScatterPlot = ({ data }) => {
    const data_new = [
        {
            id: "Loss",
            data: data.map((row) => ({
                x: row["Total"],
                y: row["Rand Damage"],
                id: row["DESCRIPTION"],
            })),
        },
    ];

    return (
        <ResponsiveScatterPlot
            data={data_new}
            margin={{ top: 60, right: 140, bottom: 70, left: 90 }}
            xScale={{ type: "linear", min: 0, max: "auto" }}
            xFormat=">-.2f"
            yScale={{ type: "linear", min: 0, max: "auto" }}
            yFormat=">-.2f"
            blendMode="multiply"
            axisTop={null}
            axisRight={null}
            colors={{ scheme: "category10" }}
            tooltip={({ node }) => (
                <div>
                    <strong>{node.data.id}</strong>
                    <br />
                    Total: {node.data.x}
                    <br />
                    Rand Damage: {node.data.y}
                </div>
            )}
            axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Frequency of total fire loss per Industry",
                legendPosition: "middle",
                legendOffset: 46,
            }}
            axisLeft={{
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Severity of Rand Damage (R)",
                legendPosition: "middle",
                legendOffset: -80,
            }}
            legends={[
                {
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 130,
                    translateY: 0,
                    itemWidth: 100,
                    itemHeight: 12,
                    itemsSpacing: 5,
                    itemDirection: "left-to-right",
                    symbolSize: 12,
                    symbolShape: "circle",
                    effects: [
                        {
                            on: "hover",
                            style: {
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
        />
    );
};
export default ScatterPlot;
