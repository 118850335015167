import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axiosClient from "../../axios";
import { useState, useEffect } from "react";
import { compareAsc, format } from "date-fns";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import NewInsurer from "./NewInsurer";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";

const Insurances = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isNewInsurer, setIsNewInsurer] = useState(false);

    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);

    // Get List of Insurances through axios call
    const [insurances, setInsurances] = useState([]);
    useEffect(() => {
        setBackdropOpen(true);
        async function getInsurances() {
            const response = await axiosClient.get("/insurances");
            // console.log(response.data);
            setInsurances(response.data);
        }
        getInsurances();

        setBackdropOpen(false);
    }, [isNewInsurer]);
    // console.log(templates);

    const newInsurer = () => {
        setIsNewInsurer(true);
    };

    const closeSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarOpen(false);
    };

    const columns = [
        { field: "id", headerName: "ID" },
        {
            field: "insurer_name",
            headerName: "Insurance",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "img_url",
            headerName: "Logo",
            flex: 1,
            renderCell: ({ row: { img_url } }) => {
                if(img_url !== null) {
                return (
                    <Typography variant="h5">
                        <Link to={`https://portal.occusurv.co.za/api/public/${img_url}`} target="_blank">
                            View Logo
                        </Link>
                    </Typography>
                );
                }else {
                    return ""
                }
            },
        },
        {
            field: "google_sheets_url",
            headerName: "Google Sheets Monthly Survey Link",
            flex: 1,
            renderCell: ({ row: { google_sheets_url } }) => {
                if(google_sheets_url !== null) {
                    return (
                        <Typography variant="h5">
                            <Link to={'https://docs.google.com/spreadsheets/d/' + google_sheets_url} target="_blank">
                                Go to Google Sheets
                            </Link>
                        </Typography>
                    );
                    }else {
                        return ""
                    }
            },
        },
        {
            field: "hex_colour",
            headerName: "Colour Code",
            flex: 1,
        },
    ];

    return (
        <Box m="20px">
            <Header title="INSURANCES" subtitle="List of insurances" />

            <Card
                variant="outlined"
                sx={{
                    marginTop: "40px",
                    borderTop: 3,
                    borderTopColor: colors.greenAccent[400],
                    borderRadius: 0,
                }}
            >
                <CardContent>
                    <Box
                        // m="40px 0 0 0"
                        mb="20px"
                        height="75vh"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: colors.blueAccent[700],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: colors.blueAccent[700],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                        }}
                    >
                        {!isNewInsurer && (
                            <Box display="flex" justifyContent="end" mb="10px">
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={newInsurer}
                                    startIcon={<AddIcon />}
                                >
                                    New Insurer
                                </Button>
                            </Box>
                        )}

                        {isNewInsurer && (
                            <NewInsurer setIsNewInsurer={setIsNewInsurer} />
                        )}

                        <DataGrid rows={insurances} columns={columns} />
                    </Box>
                    <Backdrop
                        sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={backdropOpen}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Snackbar
                        open={snackBarOpen}
                        autoHideDuration={6000}
                        onClose={closeSnackbar}
                    >
                        <Alert
                            onClose={closeSnackbar}
                            severity="success"
                            color="success"
                            sx={{ width: "100%" }}
                        >
                            Insurances loaded successfully!
                        </Alert>
                    </Snackbar>
                </CardContent>
            </Card>
        </Box>
    );
};

export default Insurances;
